import { BFFOutput } from "@tracksuit/bff/trpc";

export const getDemographicProfile = (
  filterType: string,
  data: BFFOutput["profile"]["get"]["profiles"],
) => {
  const filters = data[filterType]?.filter((p) => p.isAccountBrand);
  const { filter, percentage } =
    filters?.reduce((max, obj) => {
      return obj.percentage > (max?.percentage ?? 0) ? obj : max;
    }, filters[0]) ?? {};

  return {
    filterType,
    filter,
    percentage,
  };
};
