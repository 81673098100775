import { RouteSelector } from "$src/components/route-selector/route-selector";
import { StatSigExplainer } from "$src/components/stat-sig-explainer/stat-sig-explainer";
import { CONVERSION_IDS } from "$src/lib/consts";
import { useConversionComparison } from "$src/queries/conversion/useConversionComparison";
import { useFilters } from "$src/stores/useFilters";
import { Comparison, ComparisonProps } from "$src/views/comparison/comparison";
import { FilterBar } from "$src/views/filter-bar/filter-bar";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  formatDateRange,
  getComparisonPeriodLabel,
  getDatePeriod,
  getDateRangeLabel,
} from "@tracksuit/frontend/utils";

import {
  METRIC_ROUTE_MAP,
  getMetricIdFromParam,
  useConversionLabel,
  useMetricParam,
} from "../../utils";
import styles from "./comparison.module.css";

export const ConversionComparisonRoute = () => {
  const { conversionComparison } = useFlags();
  const [{ datePeriod, brandIdList }] = useFilters((s) => [s.filters]);
  const navigate = useNavigate();
  const metricParam = useMetricParam("/conversion/comparison");
  const selectedMetricId = useMemo(
    () => getMetricIdFromParam(metricParam),
    [metricParam],
  );
  const selectedLabel = useConversionLabel(selectedMetricId);
  const comparisonRef = useRef<HTMLDivElement>(null);
  const comparisonRange = useMemo(
    () =>
      getDatePeriod(
        datePeriod.start,
        datePeriod.end,
        datePeriod.comparisonPeriod,
      ),
    [datePeriod.start, datePeriod.end, datePeriod.comparisonPeriod],
  );
  const { data, loading, sufficient } =
    useConversionComparison(selectedMetricId);
  const chartData: Maybe<ComparisonProps["data"]> = useMemo(
    () =>
      data?.metrics.map((metric) => ({
        label: metric.brandName,
        differences: [
          {
            percentage: metric.percentage,
            isSignificant: metric.isSignificant,
          },
        ],
        metrics: [metric.sourcePercentage, metric.targetPercentage],
      })),
    [data],
  );

  useEffect(() => {
    if (!conversionComparison) {
      navigate("/conversion");
    }
  });

  return (
    <div className={styles.page}>
      <RouteSelector
        title="Show comparisons for:"
        label={selectedLabel}
        selected={
          selectedMetricId
            ? { id: metricParam!, label: selectedLabel }
            : undefined
        }
        options={CONVERSION_IDS.map((m) => ({
          id: METRIC_ROUTE_MAP[m] as string,
          label: useConversionLabel(m),
        }))}
        pathname="/conversion/comparison"
      />

      <FilterBar
        filters={{
          demographic: true,
          brand: { multiSelect: true, includeCompetitorAverage: true },
        }}
        actions={{
          copyAsImage: sufficient ? comparisonRef : undefined,
        }}
        quality={!!brandIdList.length ? data?.sampleSizeQuality : "STRONG"}
      />

      <Comparison
        ref={comparisonRef}
        titlePrefix={`Comparing ${selectedLabel.toLowerCase()}`}
        data={chartData}
        quality={data?.sampleSizeQuality}
        sufficient={sufficient}
        dataRepresentationToggle={false}
        configs={[
          {
            label:
              formatDateRange(comparisonRange.start, comparisonRange.end) ?? "",
            subLabel: getComparisonPeriodLabel(datePeriod.comparisonPeriod),
            color: "var(--color-purple-200)",
            valueColor: "var(--color-purple-800)",
          },
          {
            label: formatDateRange(datePeriod.start, datePeriod.end) ?? "",
            subLabel: getDateRangeLabel(datePeriod.start, datePeriod.end, true),
            color: "var(--color-purple-500)",
          },
        ]}
        loading={loading}
      />
      {!loading && data?.sampleSizeQuality !== "INSUFFICIENT" && (
        <StatSigExplainer className={styles["stat-sig-explainer"]} />
      )}
    </div>
  );
};
