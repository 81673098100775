import { Checkbox } from "$src/components/checkbox/checkbox";
import {
  ControlledDropdown,
  DropdownItem,
} from "$src/components/dropdown/dropdown";
import { ReactNode, useState } from "react";

import styles from "./multi-selectable.module.css";

export type MultiSelectableProps = {
  label: string;
  selected?: string;
  multiselect: boolean;
  selectAll: {
    onChange(state: boolean): void;
    isActive: boolean;
  };
  items: {
    label: string;
    isActive: boolean;
    onClick(): void;
  }[];
  children?: ReactNode;
  className?: string;
};

export const MultiSelectable = ({
  label,
  selected,
  multiselect,
  selectAll,
  items,
  children,
  className,
}: MultiSelectableProps) => {
  const [open, setOpen] = useState(false);

  return (
    <ControlledDropdown
      open={open}
      onChange={setOpen}
      theme="select"
      label={`${label}${multiselect ? "s" : ""}`}
      selected={selected}
      className={className}
      constrained
    >
      {children}
      {multiselect && (
        <div className={styles.head}>
          <DropdownItem
            id={`${label}-filter`}
            onClick={() => {
              selectAll.onChange(!selectAll.isActive);
            }}
          >
            <Checkbox
              style={{ pointerEvents: "none" }}
              label={`All ${label.toLowerCase()}s`}
              checked={selectAll.isActive}
            />
          </DropdownItem>
        </div>
      )}
      {items.map((item) => (
        <DropdownItem
          key={item.label}
          id={`${label}-filter`}
          active={item.isActive}
          onClick={() => {
            item.onClick();
            if (!multiselect) {
              setOpen(false);
            }
          }}
        >
          {multiselect ? (
            <Checkbox
              style={{ pointerEvents: "none" }}
              label={item.label}
              checked={item.isActive}
            />
          ) : (
            item.label
          )}
        </DropdownItem>
      ))}
    </ControlledDropdown>
  );
};
