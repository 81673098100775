import { cx } from "$src/lib/utils";
import type { CSSProperties, ComponentProps, ReactNode } from "react";
import Skeleton from "react-loading-skeleton";

import styles from "./tag.module.css";

export type TagProps = {
  /** Label of the tag */
  label?: ReactNode;
  /** Optional value label */
  value?: string;
  /** Optional action of the tag */
  action?: string;
  /** Optional callback for tag active */
  onAction?(): void;
  /** Accent colour used throughout */
  color?: string;
  /** Whether the tag is highlighted as active */
  active?: boolean;
  /** Optional loading state */
  loading?: boolean;
  /** Whether to hide action until hover */
  hideAction?: boolean;
  /** Optional large variant */
  large?: boolean;
  /** Whether to ellipse the value */
  ellipsed?: boolean;
} & ComponentProps<"div">;

/**
 * @component
 * Tag with optional action
 */
export const Tag = ({
  label,
  value,
  action,
  onAction,
  color,
  active = false,
  loading = false,
  ellipsed = true,
  hideAction,
  large,
  className,
  style,
  ...props
}: TagProps) => {
  return (
    <div
      className={cx(
        styles.tag,
        active && styles.active,
        large && styles.large,
        loading && styles.loading,
        className,
      )}
      style={{ "--tag-color": color, ...style } as CSSProperties}
      {...props}
    >
      <div className={styles.content}>
        {(color !== undefined || loading) && <span className={styles.dot} />}
        {!!value && !loading && <span className={styles.value}>{value}</span>}
        <span
          className={cx(
            styles.label,
            ellipsed && styles.ellipsed,
            !!value && styles["with-value"],
          )}
        >
          {loading ? <Skeleton width="13ch" /> : label}
        </span>
      </div>
      {action && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            onAction?.();
          }}
          className={cx(styles.action, hideAction && styles.hidden)}
        >
          {action}
        </span>
      )}
    </div>
  );
};
