import { Button } from "$src/components/button/button";
import { DesktopOnly } from "$src/components/desktop-only/desktop-only";
import { EmptyState } from "$src/components/empty-state/empty-state";
import { Input } from "$src/components/input/input";
import { cx } from "$src/lib/utils";
import { useMilestones } from "$src/queries/useMilestones";
import { media } from "$src/styles";
import { useMediaQuery } from "@react-hookz/web";
import Fuse from "fuse.js";
import { type ComponentProps, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import type { BFFOutput } from "@tracksuit/bff/trpc";
import { toDate } from "@tracksuit/frontend/utils";

import { MilestoneEditor } from "./lib/milestone-editor/milestone-editor";
import { MilestoneHeader } from "./lib/milestone-header/milestone-header";
import { MilestoneListItem } from "./lib/milestone-list-item/milestone-list-item";
import styles from "./milestones.module.css";

export type Milestone = NonNullable<BFFOutput["milestones"]["list"]>[0];

/**
 * @component
 * Milestones view
 */
export const Milestones = ({ className, ...props }: ComponentProps<"div">) => {
  const { data, loading } = useMilestones();
  const [editing, setEditing] = useState(false);
  const [milestone, setMilestone] = useState<Milestone>();
  const isDesktop = useMediaQuery(media["laptop"]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredActiveMilestones, setFilteredActiveMilestones] = useState<any>(
    [],
  );
  const [filteredFutureMilestones, setFilteredFutureMilestones] = useState<any>(
    [],
  );

  const fuseActive = useMemo(() => {
    return new Fuse(data.active, {
      keys: ["description", "brandName"],
      threshold: 0.4,
    });
  }, [data.active]);

  const fuseFuture = useMemo(() => {
    return new Fuse(data.future, {
      keys: ["description", "brandName", "channel"],
      threshold: 0.4,
    });
  }, [data.future]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredActiveMilestones(data.active);
      setFilteredFutureMilestones(data.future);
    } else {
      const activeResults = fuseActive
        .search(searchQuery)
        .map((result) => result.item);
      const futureResults = fuseFuture
        .search(searchQuery)
        .map((result) => result.item);
      setFilteredActiveMilestones(activeResults);
      setFilteredFutureMilestones(futureResults);
    }
  }, [searchQuery, fuseFuture, fuseActive, data.active, data.future]);

  const showEmptyState = useMemo(() => {
    return !loading && data.active.length === 0 && data.future.length === 0;
  }, [loading, data.active.length, data.future.length]);

  const showMilestoneHeader = (isFutureMilestone: boolean) => {
    if (
      isFutureMilestone &&
      filteredFutureMilestones.length > 0 &&
      filteredActiveMilestones.length > 0
    ) {
      return (
        <MilestoneHeader
          heading={`Future Milestones (${filteredFutureMilestones.length})`}
          text="These will be available in the Timeline when the milestone’s period has been surpassed by a complete month’s worth of data"
        />
      );
    }

    if (
      !isFutureMilestone &&
      filteredFutureMilestones.length > 0 &&
      filteredActiveMilestones.length > 0
    ) {
      return (
        <MilestoneHeader
          heading={`Milestones (${filteredActiveMilestones.length})`}
          text="These will display in the timeline graph when milestones are relevant to the graph's selected date range."
        />
      );
    }

    return null;
  };

  return isDesktop ? (
    <>
      {showEmptyState ? (
        <div className={styles.empty}>
          <EmptyState
            emoji="🗓️"
            heading="Record brand activity on the Timeline"
            text="Add a milestone to keep track of key campaigns and other important moments. This makes it easy to correlate your brand health with your activity."
          >
            <div className={styles["empty-state-button"]}>
              <Button
                label="Add milestone"
                loading={loading}
                onClick={() => setEditing(true)}
              />
            </div>
          </EmptyState>
        </div>
      ) : (
        <>
          <h3 className={styles["sub-heading"]}>
            Keep track of brand activity for you and your competitor brands
          </h3>
          <div className={cx(styles.milestones, className)} {...props}>
            <div
              className={cx(
                styles["header-bar"],
                !(data.active.length >= 10 || data.future.length >= 10) &&
                  styles["button-right"],
              )}
            >
              {data.active.length >= 10 ||
                (data.future.length >= 10 && (
                  <div className={styles.search}>
                    <Input
                      label="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                ))}
              <Button
                label="Add milestone"
                loading={loading}
                onClick={() => setEditing(true)}
                data-testid="add-milestone-button"
              />
            </div>
            <div className={styles.list}>
              {!loading && (
                <>
                  <span className={styles.header}>Description</span>
                  <span className={styles.header}>Brand</span>
                  <span className={styles.header}>Start date</span>
                  <span className={styles.header}>End date</span>
                  <span className={styles.header}>Channel</span>
                  <span className={styles.header}>Cost</span>
                  <span className={styles.header}></span>
                </>
              )}
              {loading &&
                Array.from({ length: 3 }).map((_, i) => (
                  <>
                    {Array.from({ length: 7 }).map((_, i) => (
                      <div
                        className={styles.header}
                        key={i}
                        data-testid="loading-indicator"
                      >
                        <Skeleton width="10ch" />
                      </div>
                    ))}
                  </>
                ))}
              {data.future.length > 0 && (
                <>
                  {showMilestoneHeader(true)}
                  {searchQuery.trim() === "" ? (
                    data.future
                      .sort(
                        (a, b) =>
                          toDate(b.startDate).getTime() -
                          toDate(a.startDate).getTime(),
                      )
                      .map((milestone) => (
                        <MilestoneListItem
                          key={milestone.id}
                          milestone={milestone}
                          onOpen={() => {
                            setMilestone(milestone);
                            setTimeout(() => setEditing(true), 0);
                          }}
                        />
                      ))
                  ) : (
                    <>
                      {filteredFutureMilestones.length > 0 &&
                        filteredFutureMilestones.map((milestone: any) => (
                          <MilestoneListItem
                            key={milestone.id}
                            milestone={milestone}
                            onOpen={() => {
                              setMilestone(milestone);
                              setTimeout(() => setEditing(true), 0);
                            }}
                          />
                        ))}
                    </>
                  )}
                </>
              )}
              {!!data.active?.length && showMilestoneHeader(false)}
              {searchQuery.trim() === ""
                ? data.active
                    .sort(
                      (a, b) =>
                        toDate(b.startDate).getTime() -
                        toDate(a.startDate).getTime(),
                    )
                    .map((milestone) => (
                      <MilestoneListItem
                        key={milestone.id}
                        milestone={milestone}
                        onOpen={() => {
                          setMilestone(milestone);
                          setTimeout(() => setEditing(true), 0);
                        }}
                      />
                    ))
                : filteredActiveMilestones.length > 0 &&
                  filteredActiveMilestones.map((milestone: any) => (
                    <MilestoneListItem
                      key={milestone.id}
                      milestone={milestone}
                      onOpen={() => {
                        setMilestone(milestone);
                        setTimeout(() => setEditing(true), 0);
                      }}
                    />
                  ))}
            </div>
            <p className={styles.info}>
              Choose the Milestones you want visible on the{" "}
              <Link to="/funnel/timeline">Timeline</Link> view by using the
              hide/show icon on the far right.
            </p>
          </div>
        </>
      )}
      <MilestoneEditor
        open={editing}
        onClose={() => {
          setMilestone(undefined);
          setEditing(false);
        }}
        milestone={milestone}
      />
    </>
  ) : (
    <div className={styles.mobile}>
      <DesktopOnly />
    </div>
  );
};
