// v8 ignore start: HACK madi to come back to fix
import { Collapsible } from "$src/components/collapsible/collapsible";
import {
  EMPTY_STATE_CONTENT,
  EmptyState,
} from "$src/components/empty-state/empty-state";
import { useActiveFiltersLabel } from "$src/hooks/useActiveFiltersLabel";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import {
  COMPETITOR_AVERAGE_ID,
  COMPETITOR_COLORS,
  STAGE_EXPLANATIONS,
} from "$src/lib/consts";
import { useRegionalize } from "$src/lib/regionalization";
import { cx, percentageFormatter } from "$src/lib/utils";
import { useStatementsMetrics } from "$src/queries/statements";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { useDeepCompareEffect, useMediaQuery } from "@react-hookz/web";
import {
  type ComponentProps,
  createContext,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";

import type { QuestionType } from "@tracksuit/frontend/schemas";

import { DesktopOnly } from "../../components/desktop-only/desktop-only";
import { Legend } from "../../components/legend/legend";
import { useAvailableFilters } from "../../queries/useAvailableFilters";
import { media } from "../../styles";
import { getMaxValue } from "./lib/getMaxValue";
import { Statement } from "./lib/statement/statement";
import styles from "./statements.module.css";

export type StatementsProps = ComponentProps<"div">;

export const ActiveBrandContext = createContext<{
  active?: number[];
  setActive?: (ids: number[]) => void;
}>({});

export const useMetricLabel = (metric?: QuestionType) => {
  const regionTerminology = useRegionalize(
    STAGE_EXPLANATIONS[metric ?? "UNPROMPTED_AWARENESS"]?.explanation ?? "",
  );
  return metric
    ? `Those who ${STAGE_EXPLANATIONS[metric]?.verb} ${regionTerminology} believe it...`
    : "";
};

const getCompetitorColor = (
  brandId: number,
  i: number,
  isAccountBrand: boolean,
) => {
  const color =
    COMPETITOR_COLORS[i] ?? COMPETITOR_COLORS[i - COMPETITOR_COLORS.length + 1];

  if (isAccountBrand) {
    return {
      default: "var(--color-purple-100)",
      active: "var(--color-purple-500)",
      text: "var(--color-off-white)",
    };
  }

  if (brandId === COMPETITOR_AVERAGE_ID) {
    return {
      default: "var(--color-purple-200)",
      active: "var(--color-purple-700)",
      text: "var(--color-off-white)",
    };
  }

  return {
    default: color?.secondary,
    active: color?.primary,
    text: color?.text,
  };
};

/**
 * @component
 * Statements
 */
export const Statements = forwardRef(function Statements(
  { className, ...props }: StatementsProps,
  ref,
) {
  const account = useAccount((s) => s.active);
  const { question, brandIdList } = useFilters((s) => s.filters);
  const { availableFilters } = useAvailableFilters();
  const [activeBrands, setActiveBrands] = useState<number[]>([]);
  const [hydrated, setHydrated] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const filterLabel = useActiveFiltersLabel();
  const { data, loading } = useStatementsMetrics();
  const statements = useMemo(
    () =>
      data?.statements.map(({ statement, metrics }) => ({
        statement,
        metrics: metrics?.filter((metric) =>
          brandIdList?.includes(metric.brandId ?? 0),
        ),
      })),
    [data, brandIdList],
  );
  const [colorMap, setColorMap] = useState<{
    [brand: number]: { default: string; active: string; text: string };
  }>([]);
  const state = useMemo(() => {
    if (!brandIdList.length) {
      return "brands";
    }
    if (data?.quality === "INSUFFICIENT") {
      return "sample";
    }
    return "data";
  }, [data?.quality, brandIdList]);
  const analytics = useAnalytics();
  const maxValue = useMemo(() => getMaxValue(statements), [statements]);
  const metricLabel = useMetricLabel(question);
  const isDesktop = useMediaQuery(media.laptop);

  useEffect(() => {
    setHydrated(false);
  }, [account]);

  useEffect(() => {
    if (hydrated || !account) {
      return;
    }

    setActiveBrands([account.brandId]);
    setHydrated(true);
  }, [hydrated, account]);

  useDeepCompareEffect(() => {
    if (availableFilters && account) {
      setColorMap(
        Object.fromEntries(
          [...availableFilters.brands, { id: COMPETITOR_AVERAGE_ID }].map(
            ({ id }, i) => [
              id,
              getCompetitorColor(id as number, i, id === account.brandId),
            ],
          ),
        ),
      );
    }
  }, [availableFilters]);

  useEffect(() => {
    expanded &&
      analytics?.track(AnalyticsEvents.ExpandAccordion, {
        view: "Statements",
        metadata: {
          accordion: "Question",
        },
      });
  }, [analytics, expanded]);

  return (
    <div
      ref={ref as any}
      className={cx(className, styles.container)}
      {...props}
    >
      {isDesktop ? (
        <>
          {state === "brands" && <EmptyState {...EMPTY_STATE_CONTENT.brands} />}
          {state === "sample" && <EmptyState {...EMPTY_STATE_CONTENT.sample} />}
          {state === "data" && (
            <>
              <div
                className={cx(
                  styles["title-container"],
                  expanded && styles.open,
                )}
              >
                <Collapsible
                  triggerAlignment="center"
                  trigger={<h2 className={cx(styles.title)}>{metricLabel}</h2>}
                  onChange={setExpanded}
                >
                  <div className={cx(styles["question"])}>
                    <h3 className={styles["question-title"]}>
                      How is this asked in the survey?
                    </h3>
                    <p>Do you feel {account?.brandName}:</p>
                    <ul className={styles["question-list"]}>
                      {data?.statements.map(({ statement }) => (
                        <li key={statement}>{statement}</li>
                      ))}
                    </ul>
                    <p className={styles["question-info"]}>
                      <em>
                        Repeated for all competitors that the respondent is
                        aware of.
                      </em>
                    </p>
                  </div>
                </Collapsible>
              </div>
              <div className={styles["content"]}>
                <div className={styles["filters-label"]}>
                  Filtered by: {filterLabel}
                </div>
                <ActiveBrandContext.Provider
                  value={{
                    active: activeBrands,
                    setActive: setActiveBrands,
                  }}
                >
                  <div className={styles.statements}>
                    <div className={styles["statements-inner"]}>
                      <div className={styles["axis-labels"]}>
                        <span>0%</span>
                        <span>{percentageFormatter.format(maxValue)}</span>
                      </div>
                      <div className={styles["statements-list"]}>
                        {loading
                          ? Array.from({ length: 5 }).map((_, i) => (
                              <Statement loading key={i} />
                            ))
                          : statements?.map(({ statement, metrics }) => (
                              <Statement
                                statement={statement}
                                data={metrics?.map((metric) => ({
                                  ...metric,
                                  color: colorMap[metric.brandId ?? 0]!,
                                }))}
                                maxValue={maxValue}
                                key={statement}
                              />
                            ))}
                      </div>
                    </div>

                    <Legend
                      className={styles.legend}
                      items={
                        statements?.[0]?.metrics?.map(
                          ({ brandId, brandName }) => ({
                            id: brandId,
                            label: brandName,
                            color: colorMap[brandId ?? 0]?.active ?? "",
                          }),
                        ) ?? []
                      }
                      maxLength={20}
                      loading={loading}
                      active={activeBrands}
                      onActiveChanged={(ids) =>
                        setActiveBrands(ids as number[])
                      }
                    />
                  </div>
                </ActiveBrandContext.Provider>
              </div>
            </>
          )}
        </>
      ) : (
        <DesktopOnly />
      )}
    </div>
  );
});
/* v8 ignore end */
