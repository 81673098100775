import {
  DropdownItem,
  DropdownSection,
} from "$src/components/dropdown/dropdown";
import { RouteSelector } from "$src/components/route-selector/route-selector";
import { CONVERSION_IDS } from "$src/lib/consts";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import {
  METRIC_ROUTE_MAP,
  getMetricIdFromParam,
  useConversionLabel,
  useMetricParam,
} from "../../utils";
import styles from "./layout.module.css";

export const ConversionTimelineLayout = () => {
  const { conversionTimeline } = useFlags();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const metricParam = useMetricParam("/conversion/timeline/metric");
  const selectedMetricId = useMemo(
    () => getMetricIdFromParam(metricParam),
    [metricParam],
  );
  const selectedLabel = useConversionLabel(selectedMetricId);
  const selectorLabel = useMemo(() => {
    if (pathname === "/conversion/timeline") {
      return "All conversion metrics";
    }
    return selectedLabel;
  }, [selectedLabel, pathname]);

  useEffect(() => {
    if (!conversionTimeline) {
      navigate("/conversion");
    }
  }, [navigate, conversionTimeline]);

  return (
    <div className={styles.page}>
      <RouteSelector
        title="Show trends for:"
        label={selectorLabel}
        sectionTitle="View for all brands"
        selected={
          selectedMetricId
            ? { id: metricParam!, label: selectedLabel }
            : undefined
        }
        options={CONVERSION_IDS.map((m) => ({
          id: METRIC_ROUTE_MAP[m] as string,
          label: useConversionLabel(m),
        }))}
        pathname="/conversion/timeline/metric"
      >
        <DropdownSection label="View for one brand" divider={false}>
          <DropdownItem
            id="timeline-view"
            active={pathname === "/conversion/timeline"}
            onClick={() => {
              navigate("/conversion/timeline");
            }}
          >
            All conversion metrics
          </DropdownItem>
        </DropdownSection>
      </RouteSelector>

      <Outlet />
    </div>
  );
};
