import { percentageFormatter } from "$src/lib/utils";
import Skeleton from "react-loading-skeleton";

import styles from "./stat.module.css";

type StatProps = {
  value?: number;
  label: string;
  loading: boolean;
};

export const Stat = ({ value, label, loading }: StatProps) => {
  return (
    <div className={styles.stat}>
      <h3 className={styles.label}>{label}</h3>
      <span className={styles.value}>
        {loading ? (
          <Skeleton width="3ch" />
        ) : (
          percentageFormatter.format(value!)
        )}
      </span>
    </div>
  );
};
