import { Checkbox } from "$src/components/checkbox/checkbox";
import { DesktopOnly } from "$src/components/desktop-only/desktop-only";
import { Dropdown, DropdownItem } from "$src/components/dropdown/dropdown";
import { Ellipsed } from "$src/components/ellipsed/ellipsed";
import {
  EMPTY_STATE_CONTENT,
  EmptyState,
} from "$src/components/empty-state/empty-state";
import { ToggleDataRepresentation } from "$src/components/filters/data-representation/data-representation";
import { Legend } from "$src/components/legend/legend";
import {
  LineChart,
  LineChartMarker,
} from "$src/components/line-chart/line-chart";
import { StatSigExplainer } from "$src/components/stat-sig-explainer/stat-sig-explainer";
import { Tag } from "$src/components/tag/tag";
import { Tooltip } from "$src/components/tooltip/tooltip";
import { useActiveFiltersLabel } from "$src/hooks/useActiveFiltersLabel";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useRequiredDatesCount } from "$src/hooks/useRequiredDatesCount";
import {
  CATEGORY_PENETRATION_COLOR,
  CATEGORY_PENETRATION_ID,
} from "$src/lib/consts";
import { cx, formatNumberRepresentation } from "$src/lib/utils";
import { useMilestones } from "$src/queries/useMilestones";
import { useFilters } from "$src/stores/useFilters";
import { media } from "$src/styles";
import { useMediaQuery } from "@react-hookz/web";
import { sentenceCase } from "change-case";
import { isSameMonth } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { X } from "lucide-react";
import { type ComponentProps, forwardRef, useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import type { SampleQuality } from "@tracksuit/frontend/schemas";
import { toDate } from "@tracksuit/frontend/utils";

import { useCreateMilesontesWarningStore } from "./lib/useMilestonesWarning";
import { useTimelineConfig } from "./lib/useTimelineConfig";
import { useChartData } from "./lib/utils";
import styles from "./timeline.module.css";

type TimelineData = {
  metrics: {
    percentage: number;
    population?: number;
    waveDate: string;
  }[];
  difference: {
    percentage?: number;
    population?: number;
    isSignificant: boolean;
  };
};

export type TimelineProps = {
  title: string;
  data?: ({
    label: string;
    id: string;
    color: string;
  } & TimelineData)[];
  quality?: SampleQuality;
  loading?: boolean;
  dataUnselected?: boolean;
  milestones: LineChartMarker[];
  categoryPenetration?: TimelineData | null;
  activeDatasets: string[];
  onActiveDatasetsChange?(datasets: string[]): void;
  dataRepresentationToggle?: boolean;
} & ComponentProps<"div">;

export const Timeline = forwardRef(function Timeline(
  {
    title,
    data,
    quality,
    milestones,
    categoryPenetration,
    activeDatasets,
    onActiveDatasetsChange,
    dataUnselected,
    dataRepresentationToggle = true,
    loading,
    className,
    ...props
  }: TimelineProps,
  ref,
) {
  const [{ dataRepresentation }, setFilters] = useFilters((s) => [
    s.filters,
    s.set,
  ]);
  const {
    data: { future: futureMilestones },
  } = useMilestones();
  const filterLabel = useActiveFiltersLabel({ includeDates: "range" });
  const sufficientDates = useRequiredDatesCount(3);
  const isDesktop = useMediaQuery(media.laptop);
  const [config, setConfig] = useTimelineConfig((s) => [s.config, s.set]);
  const analytics = useAnalytics();
  const getEndValue = (metric?: TimelineData | null) => {
    const dataPoint =
      metric?.metrics && metric.metrics[metric.metrics.length - 1];
    return (
      dataPoint &&
      Number(dataPoint?.[dataRepresentation as keyof typeof dataPoint])
    );
  };
  const hasCatPen = useMemo(
    () => (categoryPenetration ? config.categoryPenetration : false),
    [categoryPenetration, config.categoryPenetration],
  );
  const { removeIncompleteMonths } = useFlags();
  const useMilestonesWarning = useCreateMilesontesWarningStore();
  const [milestonesWarningEnabled, dismissMilestonesWarning] =
    useMilestonesWarning((s) => [s.enabled, s.dismiss]);
  const availableOptions = useMemo(
    () =>
      Object.keys(config).filter((opt) => {
        switch (opt) {
          case "categoryPenetration":
            return categoryPenetration !== null;
          case "incompleteMonths":
            return !removeIncompleteMonths;
          default:
            return true;
        }
      }) as (keyof typeof config)[],
    [config, categoryPenetration, removeIncompleteMonths],
  );
  const sortedData = useMemo(
    () => data?.sort((a, b) => Number(getEndValue(b)) - Number(getEndValue(a))),
    [data],
  );
  const state = useMemo(() => {
    if (sufficientDates === false) {
      return "timeline";
    }
    if (quality === "INSUFFICIENT" && !dataUnselected) {
      return "sample";
    }
    return "data";
  }, [sufficientDates, dataUnselected, quality]);
  const includeIncompleteMonths = useMemo(() => {
    if (removeIncompleteMonths) {
      return false;
    }

    const date = data?.[0]?.metrics[data[0].metrics.length - 1]?.waveDate;
    return (
      config.incompleteMonths &&
      date &&
      isSameMonth(toDate(new Date()), toDate(date))
    );
  }, [config.incompleteMonths, data]);
  const chartData = useChartData(
    [
      sortedData,
      hasCatPen && {
        ...categoryPenetration,
        id: String(CATEGORY_PENETRATION_ID),
        color: "var(--color-green-700)",
        label: "Category penetration",
      },
    ]
      .filter(Boolean)
      .flat() as typeof sortedData,
  );

  useEffect(() => {
    if (!dataRepresentationToggle) {
      setFilters({ dataRepresentation: "percentage" });
    }
  }, [dataRepresentationToggle]);

  return (
    <>
      <div
        ref={ref as any}
        className={cx(styles.wrapper, className)}
        {...props}
      >
        {state === "sample" && (
          <EmptyState
            className={styles.empty}
            {...EMPTY_STATE_CONTENT.sample}
          />
        )}
        {state === "timeline" && (
          <EmptyState
            className={styles.empty}
            emoji="😴"
            heading="We can’t wait to show you how you’re tracking, but we need at least 3 months worth of data until we can show you a timeline view."
            text="In the meantime, why don’t you set up Milestones..."
          />
        )}
        {state === "data" && (
          <>
            {isDesktop === false ? (
              <DesktopOnly className={styles.empty} />
            ) : (
              <>
                <div className={styles.header}>
                  <h1 className={styles.title}>{title}</h1>
                </div>
                <div className={styles.card}>
                  <div className={styles.infobar}>
                    <span className={styles["infobar-filters"]}>
                      {loading === true ? (
                        <Skeleton width="100ch" />
                      ) : (
                        `Filtered by: ${filterLabel}`
                      )}
                    </span>
                    <div className={styles["infobar-actions"]}>
                      <Dropdown
                        theme="inline"
                        label="Display options"
                        className={styles["display-options"]}
                        loading={loading}
                        data-x-hidden-from-screenshot
                      >
                        {availableOptions.map((option) => (
                          <DropdownItem
                            id="timeline-option"
                            key={option}
                            active={config[option]}
                            onClick={() => {
                              analytics?.track(
                                AnalyticsEvents.ChangedTimelineOptions,
                                {
                                  options: {
                                    [option]: !config[option],
                                  },
                                },
                              );
                              setConfig({ [option]: !config[option] });
                            }}
                          >
                            <Checkbox
                              checked={!!config[option]}
                              label={sentenceCase(option)}
                              style={{ pointerEvents: "none" }}
                            />
                          </DropdownItem>
                        ))}
                      </Dropdown>
                      {dataRepresentationToggle && <ToggleDataRepresentation />}
                    </div>
                  </div>
                  {dataUnselected && !hasCatPen ? (
                    <EmptyState
                      emoji="🧳"
                      heading="Your Timeline has gone on a little vacation"
                      text={`Select items in the filter bar or turn on category penetration in ‘Display options’`}
                    />
                  ) : (
                    <div className={styles.main}>
                      <div className={styles["main-inner"]}>
                        <LineChart
                          loading={loading}
                          className={styles.chart}
                          xAxisHidden={!config.xAxis}
                          markers={config.milestones ? milestones : []}
                          yAxisHidden={!config.yAxis}
                          dataPointsVisible={config.dataPoints}
                          activeDatasets={activeDatasets}
                          projected={includeIncompleteMonths ? 1 : 0}
                          onActiveDatasetsChange={onActiveDatasetsChange}
                          numberFormat={dataRepresentation}
                          data={chartData}
                        />
                        {milestonesWarningEnabled &&
                          config.milestones &&
                          futureMilestones.length > 0 && (
                            <div className={styles["milestones-warning"]}>
                              <div>
                                <h2
                                  className={
                                    styles["milestones-warning-heading"]
                                  }
                                >
                                  You have {futureMilestones.length} future
                                  milestones(s)
                                </h2>
                                <p
                                  className={styles["milestones-warning-copy"]}
                                >
                                  These will display when the Milestone’s month
                                  has been surpassed by a complete month’s worth
                                  of data.
                                </p>
                              </div>
                              <X
                                className={styles["milestones-warning-close"]}
                                onClick={dismissMilestonesWarning}
                              />
                            </div>
                          )}
                      </div>

                      <Legend
                        items={
                          sortedData?.map((item) => ({
                            ...item,
                            value: formatNumberRepresentation(
                              getEndValue(item) ?? 0,
                              dataRepresentation,
                            ),
                          })) ?? []
                        }
                        active={activeDatasets}
                        loading={loading}
                        onActiveChanged={(active) =>
                          onActiveDatasetsChange?.(active as string[])
                        }
                      >
                        {loading === false && hasCatPen && (
                          <Tag
                            className={styles["legend-item"]}
                            data-testid="legend-item"
                            loading={loading}
                            label={
                              <Ellipsed
                                value="Category penetration"
                                maxLength={14}
                              />
                            }
                            value={formatNumberRepresentation(
                              getEndValue(categoryPenetration) ?? 0,
                              dataRepresentation,
                            )}
                            ellipsed={false}
                            color={CATEGORY_PENETRATION_COLOR}
                            onClick={() => {
                              const ids = activeDatasets.includes(
                                String(CATEGORY_PENETRATION_ID),
                              )
                                ? activeDatasets.filter(
                                    (i) =>
                                      i !== String(CATEGORY_PENETRATION_ID),
                                  )
                                : [
                                    ...activeDatasets,
                                    String(CATEGORY_PENETRATION_ID),
                                  ];
                              onActiveDatasetsChange?.(ids);
                            }}
                            active={activeDatasets.includes(
                              String(CATEGORY_PENETRATION_ID),
                            )}
                          />
                        )}
                      </Legend>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
      {(quality !== "INSUFFICIENT" || quality !== undefined) &&
        sufficientDates === true && (
          <div className={styles["tooltips"]}>
            <Tooltip
              className={styles.tooltip}
              compact={true}
              tip={
                <div className={styles["tooltip-inner"]}>
                  <p>
                    We apply a 3-month rolling average which means a monthly
                    data point includes that month, plus the two prior.
                  </p>
                  <div className={styles["tooltip-section"]}>
                    <strong>For example:</strong>
                    <ul>
                      <li>July = average of May, Jun & Jul</li>
                      <li>August = average of Jun, Jul & Aug</li>
                    </ul>
                  </div>
                </div>
              }
            >
              <p className={styles["tooltip-trigger"]}>
                How do we calculate a monthly datapoint?
              </p>
            </Tooltip>
            <div className={styles["tooltips-divider"]} />
            <StatSigExplainer />
          </div>
        )}
    </>
  );
});
