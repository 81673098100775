import { z } from "zod";

export const Date = z.date();

export type Date = z.infer<typeof Date>;

export const IntoDate = z.coerce.date().pipe(Date);

export type IntoDate = z.infer<typeof IntoDate>;

export const StartDate = z.date().brand("StartDate");

export type StartDate = z.infer<typeof StartDate>;
