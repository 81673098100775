import { Button } from "$src/components/button/button";
import { Collapsible } from "$src/components/collapsible/collapsible";
import { Layout } from "$src/components/layout/layout";
import { useCopyText } from "$src/hooks/useCopyText";
import { useRequiredDatesCount } from "$src/hooks/useRequiredDatesCount";
import { cx } from "$src/lib/utils";
import { useAccount } from "$src/stores/useAccount";
import mixins from "$src/styles/mixins.module.css";
import { TLDRDemographicProfile } from "$src/views/tldr/demographic-profile/demographic-profile";
import { TLDRFeedback } from "$src/views/tldr/feedback/feedback";
import {
  Insight,
  InsightProps,
  insightPlaintext,
} from "$src/views/tldr/insight/insight";
import { TLDROverview } from "$src/views/tldr/overview/overview";
import { Stats } from "$src/views/tldr/stats/stats";
import { format, subMonths } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { CopyIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FunnelQuestionType } from "@tracksuit/frontend/schemas";

import styles from "./tldr.module.css";

export const TLDRRoute = () => {
  const account = useAccount((s) => s.active);
  const { tldrPage } = useFlags();
  const navigate = useNavigate();
  const dateRange = {
    start: format(subMonths(new Date(), 7), "yyyy-MM-01"),
    end: format(subMonths(new Date(), 1), "yyyy-MM-01"),
  };
  const [insightsData, setInsightsData] =
    useState<typeof import("./lib/insights.json")>();
  const insights = useMemo(
    () =>
      insightsData?.find((d) => d.accountBrandId === account?.accountBrandId),
    [account, insightsData],
  );

  const hasInsights = useRequiredDatesCount(6);
  const allInsightPlaintext = useMemo(
    () =>
      insights &&
      `${insightPlaintext({
        type: "category",
        startDate: dateRange.start,
        ...insights?.category,
      } as any)}\n\n${insights.funnel
        ?.map((insight) =>
          insightPlaintext({
            type: "demographic",
            startDate: dateRange.start,
            ...insight,
          } as any),
        )
        .join("\n\n")}`,
    [insights],
  );
  const copyText = useCopyText(allInsightPlaintext ?? "", "TL;DR all insights");

  useEffect(() => {
    if (hasInsights) {
      import("./lib/insights.json").then(({ default: data }) =>
        setInsightsData(data),
      );
    }
  }, [hasInsights]);

  useEffect(() => {
    if (!tldrPage) {
      navigate("/funnel");
    }
  }, [tldrPage, navigate]);

  return (
    <Layout className={styles.tldr}>
      <div className={cx(mixins["grid-page"], styles.head)}>
        <div className={styles.tags}>
          <span className={styles.tag}>{account?.brandName}</span>
          <span className={styles.tag}>{account?.category.name}</span>
          <span className={styles.tag}>{account?.geography.name}</span>
        </div>

        <h1 className={styles.heading}>
          The <em>TL;DR</em> on{" "}
          {hasInsights ? "what's changed" : "your brand health"}
        </h1>
        <span className={styles["heading-explainer"]}>
          (a.k.a. Too Long; Didn’t Read)
        </span>
      </div>

      <div className={styles.toolbar}>
        <p>
          Heads up, this page is in beta – it may not be perfect and may still
          change based on your feedback.
        </p>
        {hasInsights && (
          <Button icon={CopyIcon} label="Copy all text" onClick={copyText} />
        )}
      </div>

      <div className={styles.main}>
        <div className={cx(styles.insights)}>
          {hasInsights === false && <TLDROverview />}
          {hasInsights === false && <TLDRDemographicProfile />}
          {hasInsights && (
            <Insight
              type="category"
              startDate={dateRange.start}
              title={insights?.category.title}
              overall={insights?.category.overall}
              demographics={
                insights?.category.demographics as InsightProps["demographics"]
              }
              competitors={null}
              interpretation={insights?.category.interpretation}
            />
          )}
          {hasInsights &&
            insights?.funnel?.map((insight) => (
              <Insight
                type="funnel"
                startDate={dateRange.start}
                questionType={insight.questionType as FunnelQuestionType}
                title={insight.title}
                overall={insight.overall}
                demographics={
                  insight.demographics as InsightProps["demographics"]
                }
                competitors={insight.competitors}
                interpretation={insight.interpretation}
              />
            ))}
        </div>

        <section className={styles.sidebar}>
          <h1 className={styles["sidebar-heading"]}>Tracksuit basics</h1>
          <div className={styles["sidebar-education"]}>
            <Collapsible
              className={cx(styles["sidebar-education-item"])}
              trigger={
                <h3 className={styles["sidebar-education-heading"]}>
                  Get the answers you need
                </h3>
              }
              defaultOpen={true}
            >
              <p>
                Visit our Help Center and master the dashboard with expert tips
                and insights
              </p>
              <p>
                <a
                  className={styles.link}
                  href="https://help.gotracksuit.com/kb/en/understanding-your-data-197448"
                  target="_blank"
                >
                  Understanding your data
                </a>
                <a
                  className={styles.link}
                  href="https://help.gotracksuit.com/kb/en/our-survey-process-and-methodology-197449"
                  target="_blank"
                >
                  Our survey process and methodology
                </a>
              </p>
            </Collapsible>
            <Collapsible
              className={styles["sidebar-education-item"]}
              trigger={
                <h3 className={styles["sidebar-education-heading"]}>
                  Invite your team
                </h3>
              }
            >
              <p>
                Drive better brand decisions by easily collaborating with your
                team
              </p>
              <Button
                className={styles.button}
                label="Add users"
                href="/settings"
                target="_blank"
                theme="secondary"
              />
            </Collapsible>
          </div>
          <TLDRFeedback />
          <Stats />
        </section>
      </div>
    </Layout>
  );
};
