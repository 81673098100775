import { Layout } from "$src/components/layout/layout";
import { NavBar } from "$src/components/nav-bar/nav-bar";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Outlet } from "react-router-dom";

export const ConversionLayout = () => {
  const { conversionTimeline, conversionComparison } = useFlags();
  return (
    <>
      {(conversionTimeline || conversionComparison) && (
        <NavBar
          items={[
            { label: "Overview", pathname: "/conversion" },
            conversionTimeline && {
              label: "Timeline",
              pathname: "/conversion/timeline",
              matchSubpaths: true,
            },
            conversionComparison && {
              label: "Comparison",
              pathname: "/conversion/comparison",
              matchSubpaths: true,
            },
          ].filter(Boolean)}
        />
      )}
      <Layout>
        <div>
          <Outlet />
        </div>
      </Layout>
    </>
  );
};
