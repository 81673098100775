import { cx } from "$src/lib/utils";
import { type ComponentProps } from "react";

import styles from "./radio-button.module.css";

export type RadioButtonProps = {
  label: string;
  name: string;
  checked: boolean;
  disabled?: boolean;
  subtext?: string;
  onChange?(): void;
} & Omit<ComponentProps<"label">, "onChange">;

/**
 * @component
 *
 */
export const RadioButton = ({
  label,
  name,
  checked,
  disabled,
  onChange,
  subtext,
  className,
  ...props
}: RadioButtonProps) => {
  return (
    <label className={cx(styles["radio-button"], className)} {...props}>
      <input
        className={styles.input}
        type="radio"
        checked={checked}
        disabled={disabled}
        onChange={() => onChange?.()}
        name={name}
      />
      <svg viewBox="0 0 24 24" fill="none" className={styles.icon}>
        <circle cx="12" cy="12" r="10" strokeWidth="1.5" />
        <circle cx="12" cy="12" r="6.5" />
      </svg>
      <div className={styles.caption}>
        <div className={styles.label}>{label}</div>
        {subtext && <div className={styles.subtext}>{subtext}</div>}
      </div>
    </label>
  );
};
