import { type LineChartMarker } from "$src/components/line-chart/line-chart";
import { CATEGORY_PENETRATION_ID } from "$src/lib/consts";
import { useMilestones } from "$src/queries/useMilestones";
import { useFilters } from "$src/stores/useFilters";
import { useDeepCompareEffect } from "@react-hookz/web";
import { format } from "date-fns";
import { useState } from "react";

import { toDate } from "@tracksuit/frontend/utils";

import type { TimelineProps } from "../timeline";

export const useChartData = (data: TimelineProps["data"]) => {
  const { dataRepresentation } = useFilters((s) => s.filters);

  return (
    data?.map((d) => ({
      id: d.id,
      color: d.color,
      labels: [d.label],
      dashed: d.id === String(CATEGORY_PENETRATION_ID),
      difference: {
        percentage: d.difference.percentage ?? 0,
        isSignificant: d.difference.isSignificant,
      },
      points: d.metrics.map((m) => ({
        x: toDate(m.waveDate),
        y:
          dataRepresentation === "percentage"
            ? m.percentage
            : m.population ?? 0,
      })),
    })) ?? []
  );
};

export const useChartMilestones = (forBrands?: number[]) => {
  const { data } = useMilestones();
  const [milestones, setMilestones] = useState<LineChartMarker[]>([]);

  useDeepCompareEffect(() => {
    const visibleMilestones = data.active.filter((milestone) => {
      if (!milestone.visible) {
        return false;
      }

      if (forBrands) {
        return !milestone.brand
          ? true
          : forBrands.includes(milestone.brand.brandId);
      }

      return true;
    });

    setMilestones(
      visibleMilestones.map((m) => ({
        id: m.id,
        title: m.description ?? "",
        description: [
          m.brand?.brandName ?? "All Brands",
          `${format(toDate(m.startDate), "dd/MM/yyyy")}${
            m.type === "CAMPAIGN" && m.endDate
              ? ` — ${format(toDate(m.endDate), "dd/MM/yyyy")}`
              : ""
          }`,
          m.type === "CAMPAIGN" ? m.channel : undefined,
          m.type === "CAMPAIGN" ? m.cost : undefined,
        ].filter(Boolean) as string[],
        date: toDate(m.startDate),
      })),
    );
  }, [forBrands, data]);

  return milestones;
};
