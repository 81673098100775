import { useFunnelQuestions } from "$src/hooks/useFunnelQuestions";
import { useValidatedParam } from "$src/hooks/useValidatedParam";
import { constantCase } from "change-case";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";

import { FunnelQuestionType } from "@tracksuit/frontend/schemas";

export const useQuestionParam = () => {
  const availableQuestions = useFunnelQuestions({});
  const { pathname } = useLocation();
  const validateQuestion = useCallback(
    (q: FunnelQuestionType) => availableQuestions.includes(q),
    [availableQuestions],
  );
  const question = useValidatedParam<FunnelQuestionType>({
    param: "question",
    deseralize: constantCase,
    validate: validateQuestion,
    enabled: pathname.startsWith("/funnel/timeline/metric"),
    fallback: "/funnel/timeline/metric/prompted-awareness",
  });

  return question;
};
