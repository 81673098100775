import DOMPurify from "dompurify";
import { Renderer, defaults, marked } from "marked";

const getText = ({ text }: any) => text;
const plaintextRenderer: Partial<Renderer> = {
  ...defaults.renderer,
  strong: getText,
  em: getText,
  codespan: getText,
  br: () => "\n",
  link: ({ title, href }) => `${title} (${href})`,
  text: getText,
};

export const toHTML = (content: string) => {
  return DOMPurify.sanitize(marked.parse(content, { async: false }));
};

export const toPlaintext = (content: string) => {
  return marked.parseInline(content, {
    renderer: plaintextRenderer as Renderer,
    async: false,
  });
};
