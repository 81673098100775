import { COMPETITOR_COLORS } from "$src/lib/consts";
import { getBrandFromId } from "$src/lib/getBrandFromId";
import { useConversionTimelineMetric } from "$src/queries/conversion";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { FilterBar } from "$src/views/filter-bar/filter-bar";
import { useChartMilestones } from "$src/views/timeline/lib/utils";
import { Timeline, TimelineProps } from "$src/views/timeline/timeline";
import { useEffect, useMemo, useRef, useState } from "react";

import {
  getMetricIdFromParam,
  useConversionLabel,
  useMetricParam,
} from "../../../utils";

export const ConversionTimelineMetricRoute = () => {
  const [{ brandIdList }, filtersReady] = useFilters((s) => [
    s.filters,
    s.ready,
  ]);
  const timelineRef = useRef(null);
  const account = useAccount((s) => s.active);
  const metricParam = useMetricParam("/conversion/timeline/metric");
  const metricId = useMemo(
    () => getMetricIdFromParam(metricParam),
    [metricParam],
  );
  const milestones = useChartMilestones(brandIdList);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [hydrated, setHydrated] = useState(false);
  const label = useConversionLabel(metricId);
  const { data, loading } = useConversionTimelineMetric(metricId);
  const chartData: Maybe<TimelineProps["data"]> = useMemo(
    () =>
      data?.metrics.map(({ brandId, waves, difference }, i) => ({
        id: String(brandId),
        label: getBrandFromId(brandId, account)?.name as string,
        color: COMPETITOR_COLORS[i]?.primary as string,
        metrics: waves,
        difference,
      })),
    [data],
  );

  useEffect(() => {
    setHydrated(false);
  }, [account]);

  useEffect(() => {
    if (!account || hydrated) {
      return;
    }

    setSelectedBrands([String(account.brandId)]);
    setHydrated(true);
  }, [account, hydrated]);

  return (
    <>
      <FilterBar
        filters={{
          date: {
            type: "range",
          },
          demographic: true,
          brand: {
            multiSelect: true,
            includeCompetitorAverage: true,
          },
        }}
        actions={{ copyAsImage: timelineRef }}
      />
      <Timeline
        title={`${label} over time`}
        data={chartData ?? []}
        quality={data?.sampleSizeQuality}
        loading={loading}
        milestones={milestones}
        ref={timelineRef}
        activeDatasets={selectedBrands}
        dataUnselected={filtersReady ? !brandIdList.length : false}
        onActiveDatasetsChange={setSelectedBrands}
        categoryPenetration={null}
        dataRepresentationToggle={false}
      />
    </>
  );
};
