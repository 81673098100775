import { useRequiredDatesCount } from "$src/hooks/useRequiredDatesCount";
import { useValidDatePeriod } from "$src/hooks/useValidDatePeriod";
import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";

import { getDatePeriod } from "@tracksuit/frontend/utils";

import { ConversionId } from "../../lib/consts";

export const useConversionComparison = (conversionId?: ConversionId) => {
  const account = useAccount((s) => s.active);
  const [{ demographics, brandIdList, datePeriod }, filtersReady] = useFilters(
    (s) => [s.filters, s.ready],
  );
  const sufficient = useRequiredDatesCount(6);
  const valid = useValidDatePeriod();
  const sourcePeriod = getDatePeriod(
    datePeriod.start,
    datePeriod.end,
    datePeriod.comparisonPeriod,
  );

  const { data, isLoading: loading } =
    apiQueryClient.conversion.compare.useQuery(
      {
        conversionId: conversionId!,
        accountBrandId: account?.accountBrandId as number,
        sourceWaveRange: {
          start: sourcePeriod.start,
          end: sourcePeriod.end,
        },
        targetWaveRange: {
          start: datePeriod.start!,
          end: datePeriod.end!,
        },
        includedBrandIds: brandIdList,
        demographicFilterIds: demographics.map(({ id }) => id),
      },
      {
        enabled:
          sufficient &&
          valid.comparison &&
          !!account &&
          !!conversionId &&
          filtersReady,
      },
    );

  return {
    data,
    sufficient,
    loading,
  };
};
