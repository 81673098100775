import {
  DropdownItem,
  DropdownSection,
} from "$src/components/dropdown/dropdown";
import { RouteSelector } from "$src/components/route-selector/route-selector";
import { useFunnelQuestions } from "$src/hooks/useFunnelQuestions";
import { regionalize } from "$src/lib/regionalization";
import { Question } from "$src/models/Funnel";
import { useAccount } from "$src/stores/useAccount";
import { sentenceCase } from "change-case";
import { useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { FunnelQuestionType } from "@tracksuit/frontend/schemas";

import styles from "./layout.module.css";
import { useQuestionParam } from "./utils";

export const FunnelTimelineLayout = () => {
  const { pathname } = useLocation();
  const availableQuestions = useFunnelQuestions({});
  const question = useQuestionParam();
  const account = useAccount((s) => s.active);
  const navigate = useNavigate();

  const getQuestionLabel = (q: FunnelQuestionType) =>
    sentenceCase(regionalize(Question[q] ?? "", account?.geography.name));
  const selectorLabel = useMemo(() => {
    if (pathname === "/funnel/timeline") {
      return "Full funnel";
    }
    return question ? getQuestionLabel(question) : undefined;
  }, [question, pathname]);

  return (
    <div className={styles.page}>
      <RouteSelector
        title="Show trends for:"
        label={selectorLabel}
        sectionTitle="View for all brands"
        selected={
          question
            ? { id: question, label: getQuestionLabel(question) }
            : undefined
        }
        options={availableQuestions.map((q) => ({
          id: q,
          label: getQuestionLabel(q as FunnelQuestionType),
        }))}
        pathname="/funnel/timeline/metric"
      >
        <DropdownSection label="View for one brand" divider={false}>
          <DropdownItem
            id="timeline-view"
            active={pathname === "/funnel/timeline"}
            onClick={() => {
              navigate("/funnel/timeline");
            }}
          >
            Full funnel
          </DropdownItem>
        </DropdownSection>
      </RouteSelector>

      <Outlet />
    </div>
  );
};
