import { ControlledCollapsible } from "$src/components/collapsible/collapsible";
import { useHover } from "$src/hooks/useHover";
import { cx } from "$src/lib/utils";
import { useRef } from "react";
import Skeleton from "react-loading-skeleton";

import styles from "./stat.module.css";

type StatProps = {
  value?: number;
  label: string;
  description: string;
  loading: boolean;
  className?: string;
};

export const Stat = ({
  value,
  label,
  description,
  loading,
  className,
}: StatProps) => {
  const ref = useRef(null);
  const hovering = useHover(ref);

  return (
    <div ref={ref} className={cx(styles.stat, className)}>
      <ControlledCollapsible className={styles.value} open={!hovering}>
        {loading ? <Skeleton width="5ch" /> : value?.toLocaleString()}
      </ControlledCollapsible>
      <div>
        <h3 className={styles.label}>{label}</h3>
        <ControlledCollapsible className={styles.description} open={hovering}>
          <span>{description}</span>
        </ControlledCollapsible>
      </div>
    </div>
  );
};
