import { create } from "zustand";
import { persist } from "zustand/middleware";

type FeedbackGivenStore = {
  feedbackGiven: boolean;
  update(): void;
};

export const useFeedbackGiven = create<FeedbackGivenStore>()(
  persist(
    (set) => ({
      feedbackGiven: false as boolean,
      update: () => set({ feedbackGiven: true }),
    }),
    { name: `tldr-feedback-given` },
  ),
);
