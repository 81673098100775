import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";

export const useConversionTimelineOverview = () => {
  const accountBrand = useAccount((s) => s.active);
  const [{ dateRange, demographics, brandId }, filtersReady] = useFilters(
    (s) => [s.filters, s.ready],
  );
  const { data, isLoading: loading } =
    apiQueryClient.conversion.fullFunnelTimeline.useQuery(
      {
        accountBrandId: accountBrand!.accountBrandId,
        brandId: brandId as number,
        demographicFilterIds: demographics.map(({ id }) => id),
        waveRange: {
          start: dateRange.start as string,
          end: dateRange.end as string,
        },
      },
      {
        enabled: filtersReady && !!accountBrand,
      },
    );

  return {
    data,
    loading,
  };
};
