import { BarChart, BarChartProps } from "$src/components/bar-chart/bar-chart";
import { cx } from "$src/lib/utils";
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import styles from "./breakdown-chart.module.css";

const BAR_COLORS: BarChartProps["data"][0]["colors"][] = [
  {
    bar: "var(--color-green-700)",
    label: "var(--color-off-white)",
    labelAlt: "var(--color-green-700)",
  },
  {
    bar: "var(--color-green-500)",
    label: "var(--color-off-white)",
    labelAlt: "var(--color-green-700)",
  },
  {
    bar: "var(--color-green-300)",
    label: "var(--color-green-700)",
    labelAlt: "var(--color-green-700)",
  },
  {
    bar: "var(--color-green-200)",
    label: "var(--color-green-700)",
    labelAlt: "var(--color-green-700)",
  },
];

type BreakdownChartProps = {
  title: string;
  description: string;
  reverse?: boolean;
  data: {
    filter: string;
    percentage: number;
  }[];
  loading?: boolean;
  className?: string;
};

export const BreakdownChart = ({
  title,
  description,
  reverse,
  loading,
  className,
  data,
}: BreakdownChartProps) => {
  const chartData: { data: BarChartProps["data"][0]; label: string }[] =
    useMemo(
      () =>
        data.map(({ filter, percentage }, i) => ({
          data: {
            value: percentage,
            colors: BAR_COLORS[i]!,
          },
          label: filter,
        })),
      [data],
    );

  return (
    <div className={cx(styles.breakdown, reverse && styles.reverse, className)}>
      <div className={styles.head}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={styles.chart}>
        {loading
          ? Array.from({ length: 4 }).map((_, i) => (
              <div className={styles["chart-item"]}>
                <BarChart
                  data={[{ value: 0.25, colors: BAR_COLORS[i] }]}
                  reverse={reverse}
                  loading
                />
                <span className={styles["chart-label"]}>
                  <Skeleton width="10ch" />
                </span>
              </div>
            ))
          : chartData.map(({ data, label }) => (
              <div className={styles["chart-item"]}>
                <BarChart data={[data]} reverse={reverse} />
                <span className={styles["chart-label"]}>{label}</span>
              </div>
            ))}
      </div>
    </div>
  );
};
