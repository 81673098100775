import { cx, percentageFormatter } from "$src/lib/utils";
import { motion } from "framer-motion";
import { type CSSProperties, type ComponentProps } from "react";

import { Tooltip } from "../tooltip/tooltip";
import styles from "./bead-chart.module.css";

type DataPoint = {
  id: number;
  value: number;
  color: {
    default: string;
    active: string;
    text: string;
  };
  label: string;
};

export type BeadChartProps = {
  /** Data for the chart */
  data: DataPoint[];
  /** Which datapoint is active */
  activeDataPoints?: number[];
  /** Callback when active point changed */
  onActiveDataPointChange?: (active: number[]) => void;
  /** Optional max value */
  maxValue?: number;
  /** Optionally show axis */
  includeAxis?: boolean;
} & ComponentProps<"div">;

/**
 * @component
 * Bead chart, used for Statements
 */
export const BeadChart = ({
  data,
  activeDataPoints,
  onActiveDataPointChange,
  maxValue = 1,
  includeAxis,
  className,
  ...props
}: BeadChartProps) => {
  const handleActiveChange = (id: number) => {
    const newActive = activeDataPoints?.includes(id)
      ? activeDataPoints.filter((active) => active !== id)
      : [...(activeDataPoints ?? []), id];

    onActiveDataPointChange?.(newActive);
  };

  return (
    <div className={cx(className)} {...props}>
      <div className={styles.chart}>
        <div className={styles["inner-track"]}>
          {data
            .sort((a, b) => a.value - b.value)
            .map((point) => (
              <motion.div
                initial={{ left: 0 }}
                animate={{ left: `${(point.value / maxValue) * 100}%` }}
                className={cx(
                  styles["bead-wrap"],
                  activeDataPoints?.includes(point.id) && styles.active,
                )}
                style={
                  {
                    "--bead-color": activeDataPoints?.includes(point.id)
                      ? point.color?.active
                      : point.color?.default,
                    "--bead-text-color": point.color?.text,
                  } as CSSProperties
                }
                data-testid="bead-chart-bead"
                data-active={
                  activeDataPoints?.includes(point.id) ? "true" : "false"
                }
                key={point.id}
              >
                <Tooltip
                  style={{ zIndex: "var(--layer-3)" }}
                  compact
                  tip={
                    <div className={styles.tip}>
                      <span className={styles["tip-value"]}>
                        {percentageFormatter.format(point.value)}
                      </span>
                      <span className={styles["tip-label"]}>{point.label}</span>
                    </div>
                  }
                >
                  <div
                    data-testid="bead-chart-bead-inner"
                    className={styles.bead}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleActiveChange(point.id);
                    }}
                  >
                    {activeDataPoints?.includes(point.id) && (
                      <span className={styles["bead-value"]}>
                        {percentageFormatter.format(point.value)}
                      </span>
                    )}
                  </div>
                </Tooltip>
              </motion.div>
            ))}
        </div>
        {includeAxis && (
          <div className={styles.axis}>
            {Array.from({ length: maxValue * 10 + 1 }).map((_, i) =>
              i % 2 === 0 ? <span key={i}>{i * 10}%</span> : null,
            )}
          </div>
        )}
      </div>
    </div>
  );
};
