import { useEffect, useMemo, useRef, useState } from "react";

import { CopyButton } from "../../../components/copy-button/copy-button";
import { apiQueryClient } from "../../../lib/api";
import { useAccount } from "../../../stores/useAccount";
import styles from "./demographic-profile.module.css";
import { BreakdownChart } from "./lib/breakdown-chart/breakdown-chart";
import { getDemographicProfile } from "./lib/getDemographicProfile";
import { getFilterLabel } from "./lib/getFilterLabel";

export const TLDRDemographicProfile = () => {
  const account = useAccount((s) => s.active);
  const ref = useRef(null);
  const [categoryProfileData, setCategoryProfileData] =
    useState<typeof import("./lib/category-profile.json")>();
  const { data: demographicProfileData, isLoading: loading } =
    apiQueryClient.profile.get.useQuery(
      {
        accountBrandId: account!.accountBrandId,
        hasTrackers: account!.trackerTopLevel === "REGIONAL",
        questionType: "PROMPTED_AWARENESS",
      },
      {
        enabled: !!account,
      },
    );

  const categoryProfile = useMemo(
    () =>
      categoryProfileData?.find(
        (d) => d.accountBrandId === account?.accountBrandId,
      )?.categoryProfile,
    [account, categoryProfileData],
  );
  const demographicProfile = useMemo(() => {
    if (categoryProfile && demographicProfileData) {
      return categoryProfile.map((profile) =>
        getDemographicProfile(
          profile.filterType,
          demographicProfileData.profiles,
        ),
      );
    }
  }, [demographicProfileData, categoryProfile]);

  useEffect(() => {
    import("./lib/category-profile.json").then(({ default: data }) =>
      setCategoryProfileData(data),
    );
  }, []);

  return !loading && !demographicProfile ? null : (
    <div ref={ref} className={styles.profile}>
      <h1 className={styles.heading}>
        Brand vs. Category: Demographic Profile
      </h1>
      <p className={styles.intro}>
        Compare your brand's profile with that of the category. If a certain
        demographic is strong in your category but underrepresented in your
        brand's consumer base, this might indicate an untapped market or growth
        opportunity.
      </p>
      <div className={styles.graphs}>
        <BreakdownChart
          className={styles.graph}
          title="Your brand"
          description="Those aware of the brand"
          reverse
          loading={loading}
          data={
            demographicProfile?.map((d) => ({
              filter: d.filter!,
              percentage: d.percentage!,
            })) ?? []
          }
        />
        <div className={styles.legend}>
          {categoryProfile?.map((d) => (
            <div className={styles["legend-item"]}>
              {getFilterLabel(d.filterType)}
            </div>
          ))}
        </div>
        <BreakdownChart
          className={styles.graph}
          title="Category"
          description="People buying in your category"
          loading={loading}
          data={
            categoryProfile?.map((d) => ({
              filter: d.filter!,
              percentage: d.percentage!,
            })) ?? []
          }
        />
      </div>
      <div className={styles.actions}>
        <CopyButton imageAreaElement={ref} />
      </div>
    </div>
  );
};
