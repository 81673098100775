import { apiQueryClient } from "$src/lib/api";
import { ConversionId } from "$src/lib/consts";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";

export const useConversionTimelineMetric = (metric?: ConversionId) => {
  const accountBrand = useAccount((s) => s.active);
  const [{ dateRange, demographics, brandIdList }, filtersReady] = useFilters(
    (s) => [s.filters, s.ready],
  );
  const { data, isLoading: loading } =
    apiQueryClient.conversion.singleMetricTimeline.useQuery(
      {
        conversionId: metric!,
        accountBrandId: accountBrand!.accountBrandId,
        includedBrandIds: brandIdList,
        demographicFilterIds: demographics.map(({ id }) => id),
        waveRange: {
          start: dateRange.start as string,
          end: dateRange.end as string,
        },
      },
      {
        enabled: filtersReady && !!accountBrand && !!metric,
      },
    );

  return {
    data,
    loading,
  };
};
