import { type RouteObject } from "react-router-dom";

import { ConversionLayout } from "./layout";
import { ConversionComparisonRoute } from "./routes/comparison/comparison";
import { ConversionOverviewRoute } from "./routes/overview/overview";
import { ConversionTimelineLayout } from "./routes/timeline/layout";
import { ConversionTimelineMetricRoute } from "./routes/timeline/metric/metric";
import { ConversionTimelineOverviewRoute } from "./routes/timeline/overview/overview";

export const conversionRoutes: RouteObject["children"] = [
  {
    Component: ConversionLayout,
    children: [
      {
        index: true,
        Component: ConversionOverviewRoute,
      },
      {
        path: "timeline",
        Component: ConversionTimelineLayout,
        children: [
          {
            index: true,
            Component: ConversionTimelineOverviewRoute,
          },
          {
            path: "metric/:metric?",
            Component: ConversionTimelineMetricRoute,
          },
        ],
      },
      {
        path: "comparison/:metric?",
        Component: ConversionComparisonRoute,
      },
    ],
  },
];
