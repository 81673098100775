import {
  ControlledDropdown,
  DropdownItem,
} from "$src/components/dropdown/dropdown";
import { useRequiredDatesCount } from "$src/hooks/useRequiredDatesCount";
import { useTldrStats } from "$src/queries/tldr";
import { useAvailableFilters } from "$src/queries/useAvailableFilters";
import { useAccount } from "$src/stores/useAccount";
import { HTMLProps, useEffect, useState } from "react";

import { Stat } from "./lib/stat";
import styles from "./stats.module.css";

const VIEWS = {
  period: "Last 6 months",
  total: "Since tracking began",
};

export const Stats = ({ className }: HTMLProps<HTMLDivElement>) => {
  const account = useAccount((s) => s.active);
  const [view, setView] = useState<keyof typeof VIEWS>("period");
  const [selectorOpen, setSelectorOpen] = useState(false);
  const { data, loading } = useTldrStats();
  const hasEnoughMonths = useRequiredDatesCount(6);

  useEffect(() => {
    if (hasEnoughMonths === false) {
      setView("total");
    }
  }, [hasEnoughMonths]);

  return (
    <div className={className}>
      <div className={styles.head}>
        <h1 className={styles.heading}>People we reached</h1>
        {hasEnoughMonths && (
          <ControlledDropdown
            theme="inline"
            className={styles.selector}
            open={selectorOpen}
            onChange={setSelectorOpen}
            align="left"
            selected={VIEWS[view]}
          >
            {Object.keys(VIEWS).map((viewOption) => (
              <DropdownItem
                id="selector-item"
                key={viewOption}
                active={view === viewOption}
                onClick={() => {
                  setView(viewOption as keyof typeof VIEWS);
                  setSelectorOpen(false);
                }}
              >
                {VIEWS[viewOption as keyof typeof VIEWS]}
              </DropdownItem>
            ))}
          </ControlledDropdown>
        )}
      </div>

      <div className={styles.stats}>
        <Stat
          className={styles.stat}
          value={data?.approached[view]}
          label="People approached"
          description="The number of people we put the survey in front of"
          loading={loading}
        />
        <Stat
          className={styles.stat}
          value={data?.qualified[view]}
          label="People in your category"
          description={`The number of people who met the criteria for your category and completed the survey`}
          loading={loading}
        />
      </div>
    </div>
  );
};
