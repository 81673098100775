import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useHover } from "$src/hooks/useHover";
import { cx } from "$src/lib/utils";
import { EllipsisIcon, MinusIcon, PlusIcon } from "lucide-react";
import { HTMLProps, useEffect, useMemo, useRef, useState } from "react";

import styles from "./ellipsed.module.css";
import { sliceText } from "./lib/sliceText";

type EllipsedProps = {
  value: string;
  maxLength: number;
} & HTMLProps<HTMLSpanElement>;

export const Ellipsed = ({ value, maxLength, ...props }: EllipsedProps) => {
  const cannotEllipse = useMemo(
    () => value.length <= maxLength,
    [value, maxLength],
  );
  const fragments = useMemo(
    () => sliceText(value, maxLength),
    [value, maxLength],
  );
  const analytics = useAnalytics();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expanded) {
      analytics?.track(AnalyticsEvents.ExpandedEllipsis, {
        value,
      });
    }
  }, [analytics, expanded]);

  return cannotEllipse ? (
    <span {...props}>{value}</span>
  ) : (
    <span {...props}>
      {expanded ? (
        <>
          <span>{value}</span>
          <MinusIcon
            className={styles.icon}
            data-testid="ellipsed-collapse"
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(false);
            }}
          />
        </>
      ) : (
        <>
          <span>{fragments.first}</span>
          <HoverEllipsisIcon
            data-testid="ellipsed-expand"
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(true);
            }}
          />
          <span>{fragments.last}</span>
        </>
      )}
    </span>
  );
};

const HoverEllipsisIcon = (props: HTMLProps<HTMLSpanElement>) => {
  const ref = useRef(null);
  const hovering = useHover(ref);
  const El = useMemo(() => (hovering ? PlusIcon : EllipsisIcon), [hovering]);

  return (
    <span ref={ref} {...props}>
      <El className={cx(styles.icon, !hovering && styles.ellipse)} />
    </span>
  );
};
