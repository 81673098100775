import { create } from "zustand";
import { persist } from "zustand/middleware";

export type Rating = 1 | 2 | 3 | 4 | 5;

type RatingStore = {
  rating?: Rating;
  rate(rating: Rating): void;
};

export const useRating = create<RatingStore>()(
  persist(
    (set) => ({
      rating: undefined,
      rate: (r) => set({ rating: r }),
    }),
    { name: `tldr-rating` },
  ),
);
