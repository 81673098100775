import {
  EMPTY_STATE_CONTENT,
  EmptyState,
} from "$src/components/empty-state/empty-state";
import { Layout } from "$src/components/layout/layout";
import { Summary } from "$src/components/summary/summary";
import { api } from "$src/lib/api";
import { cx } from "$src/lib/utils";
import { useConversionSummary } from "$src/queries/conversion/useConversionSummary";
import { useFunnel } from "$src/queries/funnel";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { FunnelConversion } from "$src/views/conversion/conversion";
import { Download } from "$src/views/download/download";
import { FilterBar } from "$src/views/filter-bar/filter-bar";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo, useRef, useState } from "react";

import styles from "./overview.module.css";

export const ConversionOverviewRoute = () => {
  const { conversionSummary } = useFlags();
  const [summaryOpen, setSummaryOpen] = useState(false);
  const { demographics, datePeriod, brandIdList } = useFilters(
    (s) => s.filters,
  );
  const { data } = useFunnel();
  const conversionRef = useRef(null);
  const account = useAccount((s) => s.active);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const state = useMemo(() => {
    if (!brandIdList.length) {
      return "brands";
    }
    if (data?.quality === "INSUFFICIENT") {
      return "sample";
    }

    return "data";
  }, [data, brandIdList]);

  const {
    data: summary,
    loading: summaryLoading,
    error: summaryError,
  } = useConversionSummary({
    enabled: summaryOpen,
  });

  const getExportUrl = async () => {
    if (!account || !datePeriod.start || !datePeriod.end) {
      throw new Error("Account or date range not set");
    }

    const res = await api.exports.funnelconversion.query({
      accountBrandId: account.accountBrandId,
      waveStartDate: datePeriod.start,
      waveEndDate: datePeriod.end,
      demographicFilter: demographics.map(({ filter }) => filter),
      demographicFilterIds: demographics.map(({ id }) => id),
      format: "XLSX",
    });

    return res?.exportUrl;
  };

  return (
    <>
      <div className={styles.inner}>
        <FilterBar
          quality={data?.quality}
          filters={{
            demographic: true,
            brand: {
              multiSelect: true,
              includeCompetitorAverage: true,
            },
            date: { type: "period" },
          }}
          actions={{
            download:
              data?.quality !== "INSUFFICIENT"
                ? () => setDownloadOpen(true)
                : undefined,
            summarise: conversionSummary
              ? () => setSummaryOpen((s) => !s)
              : undefined,
            copyAsImage:
              data?.quality !== "INSUFFICIENT" ? conversionRef : undefined,
          }}
          className={styles.nav}
        />

        {state === "brands" && (
          <div className={cx(styles.empty, styles.main)}>
            <EmptyState
              {...EMPTY_STATE_CONTENT.brands}
              className={styles["empty-inner"]}
            />
          </div>
        )}
        {state === "sample" && (
          <div className={cx(styles.empty, styles.main)}>
            <EmptyState
              {...EMPTY_STATE_CONTENT.sample}
              className={styles["empty-inner"]}
            />
          </div>
        )}
        {state === "data" && (
          <section ref={conversionRef} className={styles.main}>
            <FunnelConversion className={styles.conversion} />
          </section>
        )}
      </div>
      <Download
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
        fetcher={getExportUrl}
        heading="Downloading the Funnel Conversion"
        label="Summary"
        format="XLSX"
        stonly="exporting-your-funnel-results-tqEdhx9ASj"
      >
        <p>
          This will reflect the filters you have selected, it&apos;s currently
          only available in Excel format.
        </p>
      </Download>
      <Summary
        open={summaryOpen}
        onClose={() => setSummaryOpen(false)}
        summaries={[
          {
            content: summary?.summary,
            loading: summaryLoading,
            error: summaryError,
          },
        ]}
      />
    </>
  );
};
