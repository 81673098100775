import { cx } from "$src/lib/utils";
import { HTMLProps } from "react";

import { Ellipsed } from "../ellipsed/ellipsed";
import { Tag } from "../tag/tag";
import styles from "./legend.module.css";

type ItemId = string | number;
export type LegendProps = {
  items: {
    id: ItemId;
    label?: string;
    value?: string;
    color: string;
  }[];
  loading?: boolean;
  active: ItemId[];
  onActiveChanged(active: ItemId[]): void;
  maxLength?: number;
} & HTMLProps<HTMLDivElement>;

export const Legend = ({
  items,
  active,
  onActiveChanged,
  loading,
  maxLength = 14,
  className,
  children,
  ...props
}: LegendProps) => {
  const handleActiveChanged = (id: ItemId) => {
    const newActive = active.includes(id)
      ? active.filter((activeId) => activeId !== id)
      : [...active, id];
    onActiveChanged(newActive);
  };

  return (
    <div className={cx(styles.legend, className)} {...props}>
      {loading === true
        ? Array.from({ length: 5 }).map((_, i) => (
            <Tag className={styles["legend-item"]} loading={loading} key={i} />
          ))
        : items.map(({ id, value, label, color }) => (
            <Tag
              className={styles["legend-item"]}
              data-testid="legend-item"
              loading={loading}
              value={value}
              label={<Ellipsed value={label ?? ""} maxLength={maxLength} />}
              ellipsed={false}
              key={id}
              color={color}
              onClick={() => handleActiveChanged(id)}
              active={active.includes(id)}
            />
          ))}
      {children && (
        <div
          className={cx(
            styles["legend-extras"],
            !!items?.length && styles["with-items"],
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};
