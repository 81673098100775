import { BFFOutput } from "@tracksuit/bff/trpc";

import { COMPETITOR_AVERAGE_ID } from "./consts";

export const getBrandFromId = (
  id: number,
  account?: NonNullable<BFFOutput["metadata"]["list"]>[0],
) => {
  if (id === COMPETITOR_AVERAGE_ID) {
    return { id: COMPETITOR_AVERAGE_ID, name: "Competitor average" };
  }
  if (id === account?.brandId) {
    return { id: account.brandId, name: account?.brandName };
  }

  return account?.competitors.find((brand) => brand.id === id);
};
