import { Button } from "$src/components/button/button";
import { Modal } from "$src/components/modal/modal";
import { Textarea } from "$src/components/textarea/textarea";
import { cx } from "$src/lib/utils";
import { useToasts } from "$src/stores/useToast";
import { useEffect, useState } from "react";

import { apiQueryClient } from "../../../lib/api";
import { useAccount } from "../../../stores/useAccount";
import styles from "./feedback.module.css";
import { useFeedbackGiven } from "./lib/useFeedbackGiven";
import { Rating, useRating } from "./lib/useRating";

const RATINGS: { [key in Rating]: string } = {
  1: "Disagree",
  2: "Somewhat disagree",
  3: "Neutral",
  4: "Somewhat agree",
  5: "Agree",
};

export const TLDRFeedback = () => {
  const account = useAccount((s) => s.active);
  const [view, setView] = useState<
    "rating" | "initial-feedback" | "more-feedback"
  >("rating");
  const [rating, setRating] = useRating((s) => [s.rating, s.rate]);
  const [feedbackGiven, setFeedbackGiven] = useFeedbackGiven((s) => [
    s.feedbackGiven,
    s.update,
  ]);
  const [busy, setBusy] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const toast = useToasts();

  const addRating = apiQueryClient.tldr.rating.useMutation({
    onMutate() {
      setBusy(true);
    },
    onSuccess(r) {
      setRating(r as Rating);
    },
    onSettled() {
      setBusy(false);
    },
  });

  const addFeedback = apiQueryClient.tldr.feedback.useMutation({
    onMutate() {
      setBusy(true);
    },
    onSuccess() {
      setFeedbackGiven();
      toast.add("success", {
        id: "tldr-feedback-toast",
        title: "Your feedback has been sent!",
      });
    },
    onSettled() {
      setBusy(false);
    },
  });

  useEffect(() => {
    if (feedbackGiven) {
      setView("more-feedback");
    } else if (typeof rating !== "undefined") {
      setView("initial-feedback");
    }
  }, [rating, feedbackGiven]);

  return (
    <div className={styles.feedback}>
      <div className={cx(styles.inner, busy && styles.busy)}>
        {view === "rating" && (
          <>
            <p className={styles.text}>
              To what extent do you agree with the following statement:{" "}
              <strong>“TL;DR saved me time writing my report”</strong>
            </p>
            <div className={styles.ratings}>
              {Object.keys(RATINGS).map((rating) => (
                <span
                  className={styles.rating}
                  onClick={() => {
                    addRating.mutate({
                      brand: account?.brandName as string,
                      rating: Number(rating),
                    });
                  }}
                >
                  {RATINGS[rating as unknown as Rating]}
                </span>
              ))}
            </div>
          </>
        )}
        {view === "initial-feedback" && rating && (
          <>
            <h3 className={styles.heading}>
              {rating > 3
                ? "That’s awesome! What was the main reason for your score?"
                : "If we could do anything – what could we do to make the TL;DR better for you?"}
            </h3>
            <form
              className={styles.form}
              onSubmit={(e) => {
                e.preventDefault();
                addFeedback.mutate({
                  brand: account?.brandName as string,
                  feedback,
                });
              }}
            >
              <Textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Share your thoughts here..."
                rows={5}
                required
              />
              <Button
                className={styles["form-submit"]}
                type="submit"
                label="Send feedback"
              />
            </form>
          </>
        )}
        {view === "more-feedback" && (
          <div className={styles.twoup}>
            <div>
              <h3 className={styles.heading}>Keep building with us</h3>
              <p className={styles.text}>
                Tell us how we can make this page better for you
              </p>
            </div>
            <Button
              className={styles["feedback-button"]}
              label="Give feedback"
              onClick={() => setFeedbackOpen(true)}
            />
          </div>
        )}
      </div>

      <Modal
        heading="Keep building with us"
        onClose={() => setFeedbackOpen(false)}
        open={feedbackOpen}
      >
        <div className={styles.modal}>
          <p>Tell us how we can make this page better for you</p>
          <form
            className={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
              addFeedback.mutate({
                brand: account?.brandName as string,
                feedback,
              });
              setFeedbackOpen(false);
            }}
          >
            <Textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Share your thoughts here..."
              rows={5}
              required
            />
            <Button
              className={styles["form-submit"]}
              type="submit"
              label="Send feedback"
            />
          </form>
        </div>
      </Modal>
    </div>
  );
};
