import { useValidatedParam } from "$src/hooks/useValidatedParam";
import { ConversionId } from "$src/lib/consts";
import { regionalize } from "$src/lib/regionalization";
import { Question } from "$src/models/Funnel";
import { useAccount } from "$src/stores/useAccount";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const METRIC_ROUTE_MAP: { [metric in ConversionId]: string } = {
  [ConversionId.Values["PROMPTED_AWARENESS-CONSIDERATION"]]:
    "awareness-consideration",
  [ConversionId.Values["CONSIDERATION-PREFERENCE"]]: "consideration-preference",
};

export const useMetricParam = (basePath: string) => {
  const validateMetric = (metricParam: string) =>
    Object.values(METRIC_ROUTE_MAP).includes(metricParam);
  const { pathname } = useLocation();

  const metricParam = useValidatedParam<string>({
    param: "metric",
    validate: validateMetric,
    enabled: pathname.startsWith(basePath),
    fallback: `${basePath}/${Object.values(METRIC_ROUTE_MAP)[0]}`,
  });

  return metricParam;
};

export const getMetricIdFromParam = (param?: string) => {
  return Object.keys(METRIC_ROUTE_MAP).find(
    (key) => METRIC_ROUTE_MAP[key as ConversionId] === param,
  ) as ConversionId | undefined;
};

export const getConversionLabelFromId = (
  id: ConversionId,
  geography?: string,
) => {
  const label = id
    .split("-")
    .map((q) => Question[q])
    .join(" to ");

  return sentenceCase(regionalize(label, geography));
};

export const useConversionLabel = (conversionId?: ConversionId) => {
  const account = useAccount((s) => s.active);
  const [label, setLabel] = useState("");

  useEffect(() => {
    if (conversionId && account) {
      setLabel(getConversionLabelFromId(conversionId, account.geography.name));
    }
  }, [account, conversionId]);

  return label;
};
