import { cx, roundNumber } from "$src/lib/utils";
import { useSurveyDetails } from "$src/queries/useSurveyDetails";
import { useAccount } from "$src/stores/useAccount";
import { type ComponentProps, type ReactNode } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import styles from "./tracking-details.module.css";

const InnerSection = ({
  header,
  ...props
}: {
  header: ReactNode | string;
} & ComponentProps<"div">) => {
  return (
    <div className={styles["inner-section"]} {...props}>
      <h3 className={styles["subheader"]}>{header}</h3>
      <div className={styles["inner-child-section"]}>{props.children}</div>
    </div>
  );
};

export const TrackingDetails = ({ className }: ComponentProps<"div">) => {
  const accountBrand = useAccount((s) => s.active);
  const { data, loading } = useSurveyDetails();
  const categoryName = loading ? (
    <Skeleton inline={true} />
  ) : (
    accountBrand?.category.name
  );
  const referenceCompetitors = loading ? (
    <Skeleton />
  ) : (
    data?.otherCompetitors?.map((item) => item.name).join(", ")
  );
  const totalQualifiedPeople = loading ? (
    <Skeleton width="5ch" inline={true} />
  ) : (
    data?.sampleIncidence?.qualified.toLocaleString()
  );

  return (
    <SkeletonTheme
      baseColor="var(--color-green-200)"
      highlightColor="var(--color-off-white-opacity-dark)"
      borderRadius="var(--radius-full)"
      width="15ch"
    >
      <div className={cx(styles.container, className)}>
        <h2 className={cx(styles["header"])}>Tracking Details</h2>
        <InnerSection
          header={
            <div>
              People Surveyed:{" "}
              <strong
                className={styles["header-text-people"]}
                data-testid="people-surveyed"
              >
                {totalQualifiedPeople}
              </strong>
            </div>
          }
        >
          <div className={styles["inner-child-sub-section"]}>
            We’ve approached{" "}
            <strong>
              {loading ? (
                <div data-testid="skeleton">
                  <Skeleton width="5ch" inline={true} />
                </div>
              ) : (
                data?.sampleIncidence?.total.toLocaleString()
              )}{" "}
              people
            </strong>
            . Of those people, <strong>{totalQualifiedPeople}</strong> qualified
            to participate in {categoryName} research.
          </div>
        </InnerSection>
        <InnerSection header="Category Information">
          <div className={styles["inner-child-sub-section"]}>
            <strong>Category:</strong>&nbsp;
            {categoryName}
          </div>
          <div className={styles["inner-child-sub-section"]}>
            <strong>People in category:</strong>&nbsp;
            {loading ? (
              <div data-testid="skeleton">
                <Skeleton />
              </div>
            ) : (
              roundNumber(Number(data?.peopleInCategory))
            )}
          </div>
          <div className={styles["inner-child-sub-section"]}>
            <strong>Geography:</strong>&nbsp;
            {loading ? (
              <div data-testid="skeleton">
                <Skeleton />
              </div>
            ) : (
              accountBrand?.geography.name
            )}
          </div>
          {accountBrand &&
            (accountBrand.trackerTopLevel === "REGIONAL" ||
              accountBrand.trackers.length > 1) && (
              <div className={styles["inner-child-sub-section"]}>
                <strong>Tracker(s):</strong>&nbsp;
                {loading ? (
                  <div data-testid="skeleton">
                    <Skeleton />
                  </div>
                ) : (
                  [
                    accountBrand.trackerTopLevel === "NATIONAL" && "National",
                    ...accountBrand.trackers.map(({ stateName }) => stateName),
                  ]
                    .filter(Boolean)
                    .join(", ")
                )}
              </div>
            )}
        </InnerSection>
        <InnerSection header="Competitor Brands">
          {loading ? (
            <div data-testid="skeleton">
              <Skeleton count={5} inline={false} />
            </div>
          ) : (
            data?.selectedCompetitors?.map((competitor) => (
              <div
                className={styles["inner-child-sub-section"]}
                key={competitor.id}
              >
                {competitor.name}
              </div>
            ))
          )}
          {referenceCompetitors && (
            <div className={styles["inner-child-sub-section"]}>
              <strong>Also available to track:</strong>&nbsp;
              {referenceCompetitors}
            </div>
          )}
        </InnerSection>
        {accountBrand?.featuresConfig.statementsEnabled &&
          data?.statements &&
          data?.statements?.length > 0 && (
            <InnerSection header="Statements">
              {loading ? (
                <div data-testid="skeleton">
                  <Skeleton count={5} inline={false} />
                </div>
              ) : (
                data?.statements?.map((statement) => (
                  <div
                    className={styles["inner-child-sub-section"]}
                    key={`${statement}st`}
                  >
                    {statement}
                  </div>
                ))
              )}
            </InnerSection>
          )}
      </div>
    </SkeletonTheme>
  );
};
