import { CONVERSION_IDS, METRIC_COLORS } from "$src/lib/consts";
import { useConversionTimelineOverview } from "$src/queries/conversion";
import { useAvailableFilters } from "$src/queries/useAvailableFilters";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { FilterBar } from "$src/views/filter-bar/filter-bar";
import { useChartMilestones } from "$src/views/timeline/lib/utils";
import { Timeline, TimelineProps } from "$src/views/timeline/timeline";
import { useMemo, useRef, useState } from "react";

import { QuestionType } from "@tracksuit/frontend/schemas";

import { getConversionLabelFromId } from "../../../utils";

export const ConversionTimelineOverviewRoute = () => {
  const { brandId } = useFilters((s) => s.filters);
  const account = useAccount((s) => s.active);
  const timelineRef = useRef(null);
  const { availableFilters } = useAvailableFilters();
  const brand = useMemo(
    () => availableFilters?.brands?.find(({ id }) => id === brandId),
    [brandId, availableFilters.brands],
  );
  const milestones = useChartMilestones(brandId ? [brandId] : undefined);
  const [selectedConversions, setSelectedConversions] = useState([
    CONVERSION_IDS[0],
  ]);
  const { data, loading } = useConversionTimelineOverview();
  const chartData: Maybe<TimelineProps["data"]> = useMemo(
    () =>
      data?.metrics.map(({ id, waves, difference }, i) => ({
        id,
        label: getConversionLabelFromId(id, account?.geography.name),
        color: METRIC_COLORS[id.split("-")[0] as QuestionType] as string,
        metrics: waves,
        difference,
      })),
    [data, account],
  );

  return (
    <>
      <FilterBar
        filters={{
          date: {
            type: "range",
          },
          demographic: true,
          brand: {
            multiSelect: false,
            includeCompetitorAverage: true,
          },
        }}
        actions={{ copyAsImage: timelineRef }}
      />
      <Timeline
        title={`${
          brand?.name ? `${brand.name}: ` : ""
        }Conversion metrics over time`}
        data={chartData ?? []}
        quality={data?.sampleSizeQuality}
        loading={loading}
        milestones={milestones}
        ref={timelineRef}
        activeDatasets={selectedConversions}
        onActiveDatasetsChange={setSelectedConversions as any}
        categoryPenetration={null}
        dataRepresentationToggle={false}
      />
    </>
  );
};
