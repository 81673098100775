import { apiQueryClient } from "../../lib/api";
import { useAccount } from "../../stores/useAccount";
import { useAvailableFilters } from "../useAvailableFilters";

export const useTldrOverview = () => {
  const account = useAccount((s) => s.active);
  const { availableFilters } = useAvailableFilters();
  const { data, isLoading: loading } = apiQueryClient.tldr.overview.useQuery(
    {
      accountBrandId: account?.accountBrandId as number,
      waveEndDate: availableFilters.dates?.[0] as string,
      waveStartDate: availableFilters.dates?.[
        availableFilters.dates.length - 1
      ] as string,
    },
    { enabled: !!account && !!availableFilters.dates?.length },
  );

  return {
    data,
    loading,
  };
};
