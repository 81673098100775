import { TextLoading } from "$src/components/text-loading/text-loading";
import { toHTML, toPlaintext } from "$src/lib/markdown";
import { cx, roundNumber } from "$src/lib/utils";
import { useTldrOverview } from "$src/queries/tldr/useTldrOverview";
import { useAccount } from "$src/stores/useAccount";
import mixins from "$src/styles/mixins.module.css";
import { upperFirst } from "lodash-es";
import { CopyIcon } from "lucide-react";
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { IconButton } from "../../../components/icon-button/icon-button";
import { useCopyText } from "../../../hooks/useCopyText";
import { Stat } from "./lib/stat";
import styles from "./overview.module.css";

export const TLDROverview = () => {
  const account = useAccount((s) => s.active);
  const { data, loading } = useTldrOverview();
  const summaryPoints = useMemo(() => {
    const points = data?.summary
      ?.split("\n")
      .map((t, i) =>
        i === 0 ? upperFirst(t.replace(/^[^,]*,/, "").trim()) : t.trim(),
      )
      .filter(Boolean);

    return points?.splice(0, points?.length - 1);
  }, [data?.summary]);
  const copySummary = useCopyText(
    toPlaintext(summaryPoints?.join(" ") ?? ""),
    "TLDR highlights summary",
  );

  return (
    <div className={styles.overview}>
      <div className={styles.summary}>
        <div className={styles["summary-head"]}>
          <h1 className={styles["summary-heading"]}>Highlights</h1>
          <IconButton icon={CopyIcon} tip="Copy text" onClick={copySummary} />
        </div>
        <div className={cx(styles["summary-content"], mixins.richtext)}>
          {loading ? (
            <TextLoading lines={6} />
          ) : (
            summaryPoints?.map((point, i) => (
              <span className={styles["summary-point"]} key={i}>
                <span>👉</span>
                <span dangerouslySetInnerHTML={{ __html: toHTML(point) }} />
              </span>
            ))
          )}
        </div>
      </div>

      <div className={styles.stats}>
        <Stat
          label="Category penetration"
          value={data?.category.penetration}
          loading={loading}
        />
        <Stat
          label="Prompted awareness"
          value={data?.promptedAwareness}
          loading={loading}
        />
        <Stat
          label="Conversion (AWR-CON)"
          value={data?.conversion}
          loading={loading}
        />
      </div>
      <span className={styles.population}>
        {loading ? (
          <Skeleton width="20ch" />
        ) : (
          <>
            <strong>
              {roundNumber(data?.category.qualifiedPopulation as number)}
            </strong>{" "}
            of{" "}
            <strong>
              {roundNumber(data?.category.totalPopulation as number)}
            </strong>{" "}
            total adults in {account?.geography.name}
          </>
        )}
      </span>
    </div>
  );
};
