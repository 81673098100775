import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/useAccount";
import { useToasts } from "$src/stores/useToast";
import { isAfter, isBefore } from "date-fns";
import { useEffect, useState } from "react";

import type { BFFInput, BFFOutput } from "@tracksuit/bff/trpc";
import { toDate } from "@tracksuit/frontend/utils";

import { raygun } from "../lib/raygun";
import { useAvailableFilters } from "./useAvailableFilters";

type ErrorType = "create" | "update" | "delete";

const ERRORS: { [key in ErrorType]: string } = {
  create: "Creating milestone failed",
  update: "Updating milestone failed",
  delete: "Deleting milestone failed",
};

export const useMilestones = () => {
  const accountBrand = useAccount((s) => s.active);
  const { availableFilters } = useAvailableFilters();
  const { data, isLoading: loading } = apiQueryClient.milestones.list.useQuery(
    {
      accountBrandId: accountBrand!.accountBrandId,
    },
    {
      enabled: !!accountBrand,
    },
  );
  const [milestones, setMilestones] = useState<{
    active: NonNullable<BFFOutput["milestones"]["list"]>;
    future: NonNullable<BFFOutput["milestones"]["list"]>;
  }>({
    active: [],
    future: [],
  });

  useEffect(() => {
    if (!data || availableFilters.dates?.[0] === undefined) {
      return;
    }

    setMilestones({
      active: data.filter(
        ({ startDate }) =>
          startDate === availableFilters.dates![0] ||
          isBefore(toDate(startDate), toDate(availableFilters.dates![0]!)),
      ),
      future: data.filter(({ startDate }) =>
        isAfter(toDate(startDate), toDate(availableFilters.dates![0]!)),
      ),
    });
  }, [data]);

  return {
    data: milestones,
    loading,
  };
};

export const useMutateMilestones = () => {
  const accountBrand = useAccount((s) => s.active);
  const utils = apiQueryClient.useUtils();
  const addToast = useToasts((s) => s.add);

  const getOnMutate = (
    method: "create" | "update" | "delete",
    data:
      | BFFInput["milestones"]["create"]
      | BFFInput["milestones"]["update"]
      | BFFInput["milestones"]["delete"],
  ) => {
    return async function () {
      utils.milestones.list.cancel();

      const previousState = utils.milestones.list.getData();

      utils.milestones.list.setData(
        { accountBrandId: Number(accountBrand?.accountBrandId) },
        (old) => {
          switch (method) {
            case "create":
              return [...(old ?? []), data as any];
            case "update":
              return [
                ...(old?.filter(
                  ({ id }) =>
                    id !==
                    (data as BFFInput["milestones"]["update"]).milestoneId,
                ) ?? []),
                {
                  ...data,
                  id: (data as BFFInput["milestones"]["update"]).milestoneId,
                },
              ];
            case "delete":
              return (
                old?.filter(
                  ({ id }) =>
                    id !==
                    (data as BFFInput["milestones"]["delete"]).milestoneId,
                ) ?? []
              );
            default:
              return old;
          }
        },
      );

      return { previousState };
    };
  };

  const onError = (err: any, data: any, context: any, type: ErrorType) => {
    utils.milestones.list.setData(
      { accountBrandId: Number(accountBrand?.accountBrandId) },
      context.previousState,
    );

    raygun?.("send", err);
    addToast("error", {
      id: "milestone-error",
      title: ERRORS[type],
    });
  };

  const onSettled = () => {
    utils.milestones.list.invalidate({
      accountBrandId: accountBrand?.accountBrandId,
    });
  };

  return {
    createMilestone: apiQueryClient.milestones.create.useMutation({
      onMutate: (data) => getOnMutate("create", data),
      onSettled,
      onError: (err, data, context) => onError(err, data, context, "create"),
    }),
    updateMilestone: apiQueryClient.milestones.update.useMutation({
      onMutate: (data) => getOnMutate("update", data)(),
      onSettled,
      onError: (err, data, context) => onError(err, data, context, "update"),
    }),
    deleteMilestone: apiQueryClient.milestones.delete.useMutation({
      onMutate: (data) => getOnMutate("delete", data),
      onSettled,
      onError: (err, data, context) => onError(err, data, context, "delete"),
    }),
  };
};
