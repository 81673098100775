export const getFilterLabel = (filterType: string) => {
  switch (filterType) {
    case "Region - New Zealand":
    case "Region - Australia":
    case "Region - United States":
    case "Census division":
    case "Region - United Kingdom":
    case "Region - US":
    case "Region - Canada":
      return "Region";
    case "HH_INC":
      return "Household income";
    default:
      return filterType;
  }
};
