export const sliceText = (text: string, length: number) => {
  if (text.length <= length) {
    return { first: text, last: "" };
  }
  const middle = Math.ceil(length / 2);
  const end = text.length - Math.floor(length / 2);

  return {
    first: text.slice(0, middle),
    last: text.slice(end),
  };
};
