import { ColumnChart } from "$src/components/column-chart/column-chart";
import {
  EMPTY_STATE_CONTENT,
  EmptyState,
} from "$src/components/empty-state/empty-state";
import { ToggleDataRepresentation } from "$src/components/filters/data-representation/data-representation";
import { Tag } from "$src/components/tag/tag";
import { useActiveFiltersLabel } from "$src/hooks/useActiveFiltersLabel";
import { cx } from "$src/lib/utils";
import {
  type ComponentProps,
  ReactNode,
  forwardRef,
  useEffect,
  useMemo,
} from "react";

import type { SampleQuality } from "@tracksuit/frontend/schemas";

import { FilterDates } from "../../components/filters/date/date";
import { useFilters } from "../../stores/useFilters";
import styles from "./comparison.module.css";
import { ComparisonPeriodFilter } from "./lib/comparison-period-filter/comparison-period-filter";

export type ComparisonProps = {
  /** Title prefix */
  titlePrefix?: ReactNode;
  /** Data for the comparison */
  data?: {
    label: string;
    metrics: number[];
    differences?: {
      percentage: number;
      isSignificant: boolean;
    }[];
  }[];
  /** Sample quality of the data */
  quality?: SampleQuality;
  /** Whether the user has sufficient data */
  sufficient?: boolean;
  /** Whether view is loading */
  loading?: boolean;
  /** Whether to show question in filters label */
  showQuestion?: boolean;
  /** Legend labels and colours */
  configs: {
    label: string;
    subLabel?: string;
    color: string;
    valueColor?: string;
  }[];
  dataRepresentationToggle?: boolean;
} & ComponentProps<"div">;

/**
 * @component
 * Comparisons over time
 */
export const Comparison = forwardRef(function Comparison(
  {
    data,
    quality,
    configs,
    sufficient,
    loading,
    titlePrefix,
    showQuestion,
    dataRepresentationToggle = true,
    className,
    ...props
  }: ComparisonProps,
  ref,
) {
  const filterLabel = useActiveFiltersLabel({
    includeDates: false,
    includeQuestion: showQuestion,
  });
  const [{ brandIdList }, setFilters] = useFilters((s) => [s.filters, s.set]);
  const state = useMemo(() => {
    if (!brandIdList.length) {
      return "brands";
    }
    if (!sufficient) {
      return "compare";
    }
    if (quality === "INSUFFICIENT") {
      return "sample";
    }
    return "data";
  }, [quality, sufficient, brandIdList]);

  useEffect(() => {
    if (!dataRepresentationToggle) {
      setFilters({ dataRepresentation: "percentage" });
    }
  }, [dataRepresentationToggle]);

  return (
    <div ref={ref as any} className={cx(styles.comparison)}>
      <div className={styles.head}>
        {state === "data" && (
          <h1 className={styles.heading}>
            {titlePrefix} from{" "}
            <FilterDates
              type="comparison"
              labelFormat="period"
              theme="inline"
            />
            <span>with the</span>
            <ComparisonPeriodFilter />
          </h1>
        )}
      </div>
      <div
        className={cx(
          styles["comparison-inner"],
          state !== "data" && styles.placeholder,
          className,
        )}
        {...props}
      >
        {state === "brands" && (
          <EmptyState
            className={styles.empty}
            {...EMPTY_STATE_CONTENT.brands}
          />
        )}
        {state === "sample" && (
          <EmptyState
            className={styles.empty}
            {...EMPTY_STATE_CONTENT.sample}
          />
        )}
        {state === "compare" && (
          <EmptyState
            className={styles.empty}
            emoji="🏄"
            heading="Hang tight, good things take time."
            text="You need at least 6 months of data so you have two 3-month time periods to compare."
          />
        )}

        {state === "data" && (
          <>
            <div className={styles.statusbar}>
              <span className={styles["statusbar-filters"]}>
                Filtered by: {filterLabel}
              </span>
              {dataRepresentationToggle && <ToggleDataRepresentation />}
            </div>
            <div className={styles.content}>
              {!data?.length ? (
                <div className={styles.placeholder} />
              ) : (
                <div className={styles.charts}>
                  {data?.map(({ metrics, label, differences }, i) => (
                    <div className={styles.chart} key={i}>
                      <ColumnChart
                        theme="square"
                        compact={!differences}
                        data={metrics.map((value, k) => ({
                          value,
                          color: configs[k]?.color ?? "var(--color-purple-500)",
                          labelColor: configs[k]?.valueColor,
                        }))}
                        differences={differences}
                        labels
                      />
                      <span className={styles["chart-label"]}>{label}</span>
                    </div>
                  ))}
                </div>
              )}
              <div className={styles.legend}>
                {configs.map(({ label, subLabel, color }, i) => (
                  <div className={styles["legend-item"]} key={i}>
                    <Tag
                      className={styles["legend-item-tag"]}
                      color={color}
                      label={label}
                      loading={loading}
                    />
                    {subLabel !== undefined && (
                      <span className={styles["legend-item-sublabel"]}>
                        {subLabel}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});
