import { BeadChart } from "$src/components/bead-chart/bead-chart";
import { Button } from "$src/components/button/button";
import { Legend } from "$src/components/legend/legend";
import { Modal } from "$src/components/modal/modal";
import { useActiveFiltersLabel } from "$src/hooks/useActiveFiltersLabel";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/useAnalytics";
import { useCopyImage } from "$src/hooks/useCopyImage";
import { cx, percentageFormatter } from "$src/lib/utils";
import { useFilters } from "$src/stores/useFilters";
import mixins from "$src/styles/mixins.module.css";
import { CopyIcon, X } from "lucide-react";
import {
  type ComponentProps,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";

import type { BFFOutput } from "@tracksuit/bff/trpc";

import { ActiveBrandContext, useMetricLabel } from "../../statements";
import { getMaxValue } from "../getMaxValue";
import styles from "./statement.module.css";

export type StatementProps = {
  /** Statement */
  statement?: string;
  /** Metrics */
  data?: (NonNullable<
    BFFOutput["statements"]["getMetrics"]["statements"][0]["metrics"]
  >[0] & {
    color: {
      active: string;
      default: string;
      text: string;
    };
  })[];
  /** Whether data is loading */
  loading?: boolean;
  /** Click callback */
  onClick?(): void;
  maxValue?: number;
} & ComponentProps<"div">;

const StatementChart = ({
  data,
  maxValue,
  includeAxis,
  ...props
}: { includeAxis?: boolean } & Pick<StatementProps, "data" | "maxValue">) => {
  const { active: activeBrands, setActive } = useContext(ActiveBrandContext);

  return (
    <BeadChart
      includeAxis={includeAxis}
      activeDataPoints={activeBrands?.length ? activeBrands : []}
      onActiveDataPointChange={(ids) => setActive?.(ids)}
      maxValue={maxValue}
      data={
        data?.map(({ brandId, brandName, percentage, color }) => ({
          id: brandId,
          value: percentage,
          label: brandName,
          color,
        })) ?? []
      }
      {...props}
    />
  );
};

/**
 * @component
 * Single statement in list
 */
export const Statement = ({
  statement,
  data,
  loading,
  maxValue = 1,
  className,
  ...props
}: StatementProps) => {
  const { question } = useFilters((s) => s.filters);
  const imageRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { active: activeBrands, setActive } = useContext(ActiveBrandContext);
  const copyAsImage = useCopyImage(imageRef);
  const filterLabel = useActiveFiltersLabel();
  const analytics = useAnalytics();
  const ownMaxValue = useMemo(
    () => getMaxValue(data ? ([{ metrics: data }] as any) : undefined),
    [data],
  );
  const metricLabel = useMetricLabel(question);

  const copyStatementImage = async () => {
    await copyAsImage?.();
    analytics?.track(AnalyticsEvents.CopyImage, {
      view: "Statement Focus",
      metadata: { statement },
    });
    setOpen(false);
  };

  useEffect(() => {
    open &&
      analytics?.track(AnalyticsEvents.OpenStatement, {
        statement,
      });
  }, [open, analytics, statement]);

  return (
    <div className={cx(className)} {...props}>
      <div className={styles.statement} onClick={() => setOpen(true)}>
        <div className={styles["statement-inner"]}>
          <div className={styles["statement-head"]}>
            <div
              className={cx(styles["statement-text"], mixins["clamped-2"])}
              data-testid="statement"
            >
              {loading ? (
                <Skeleton containerTestId="loading" width="20ch" />
              ) : (
                statement
              )}
            </div>
            <span className={styles["statement-expand"]}>Expand</span>
          </div>
        </div>
        <div
          data-testid="statements-graph"
          className={styles["statement-graph"]}
        >
          <StatementChart data={data} maxValue={maxValue} />
        </div>
      </div>

      {!loading && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          large
          ref={imageRef}
          footer={
            <div className={styles["details-actions"]}>
              <Button
                label="Close"
                theme="ghost"
                onClick={() => setOpen(false)}
              />
              {!!copyAsImage && (
                <Button
                  icon={CopyIcon}
                  label="Copy as image"
                  theme="primary"
                  onClick={copyStatementImage}
                />
              )}
            </div>
          }
        >
          <X
            className={styles["details-close"]}
            onClick={() => setOpen(false)}
            data-x-hidden-from-screenshot
          />

          <div data-testid="statements-graph">
            <div>
              <span className={styles["details-eyebrow"]}>{metricLabel}</span>
              <h1
                className={styles["details-heading"]}
                data-testid="modal-details-heading"
              >
                {statement}
              </h1>
            </div>
            <div className={styles["details-content"]}>
              <div
                data-testid="statements-details-graph"
                className={styles["details-chart"]}
              >
                <StatementChart
                  data={data}
                  maxValue={ownMaxValue}
                  data-testid="statement-chart"
                  includeAxis
                />
              </div>
              <Legend
                data-testid="details-legend"
                items={
                  data
                    ?.sort((a, b) => a.percentage - b.percentage)
                    .map(({ brandId, brandName, percentage, color }) => ({
                      id: brandId,
                      label: brandName,
                      value: percentageFormatter.format(percentage),
                      color: color?.active,
                    })) ?? []
                }
                loading={false}
                active={activeBrands ?? []}
                onActiveChanged={(ids) => setActive?.(ids as number[])}
              />
            </div>
          </div>
          <span className={styles["details-filters"]}>{filterLabel}</span>
        </Modal>
      )}
    </div>
  );
};
